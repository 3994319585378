import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { clientEndpoints, peopleEndpoints } from '../../constants/apiConstants';
import ApiService from '../../services/ApiService';
import {
  IFilterPayload,
  IClientResponse,
  IClientState,
  IAddUser,
  IPaymentTransactionReportDownloadPayload,
  IAPIResponseError,
} from './ClientModel';
import {
  ILinkClientPayload,
  IUnlinkClientPayload,
} from '../../pages/PeopleDetails/ReviewDetails/ReviewDetails';

const initialState: IClientState = {
  status: 'idle',
  error: '',
  data: {} as IClientResponse,
  addClientStatus: 'idle',
  addClientError: '',
  clientStatusList: [],
  filters: {
    start: 0,
    length: 10,
    sortColumn: '',
    sortDirection: '',
    searchValue: '',
    status: 0,
  },
  notesStatus: 'idle',
  notesError: '',
  //Client Details
  clientDetails: null,
  clientDetailsStatus: 'idle',
  clientDetailsError: '',
  //New Account Owner
  newAccountOwner: null,
  newAccountOwnerStatus: 'idle',
  newAccountOwnerError: '',
  //Set new Account Owner
  setNewAccountOwnerStatus: 'idle',
  setNewAccountOwnerError: '',
};

export const fetchClients = createAsyncThunk(
  'Client/GetClients',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(clientEndpoints.list, {}, payload);
    return response.data;
  },
);

export const addClient = createAsyncThunk(
  'Client/AddUser',
  async (payload: IAddUser, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(clientEndpoints.addUser, {}, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const fetchClientDetails = createAsyncThunk(
  'Client/GetClientDetail',
  async (payload: { ssoId: string; bpId: number }) => {
    const response = await ApiService.getData(clientEndpoints.detail, {
      ssoId: payload.ssoId,
      bpId: payload.bpId,
    });
    return response.data;
  },
);

export const fetchNewAccountOwner = createAsyncThunk(
  'Client/GetNewAccountOwner',
  async (bpId: number) => {
    const response = await ApiService.getData(clientEndpoints.newAccountOwner, {
      currentOwnerBpId: bpId,
    });
    return response.data;
  },
);

export const setNewAccountOwner = createAsyncThunk(
  'Client/SetNewAccountOwner',
  async (payload: { newAccountOwnerBpId: any; existingOwnerBpId: any }) => {
    const response = await ApiService.getData(clientEndpoints.setNewAccountOwner, payload);
    return response.data;
  },
);

export const saveClientNotes = createAsyncThunk(
  'Client/SaveClientNotes',
  async (payload: { clientId: number; clientType: string; notes: string }) => {
    const response = await ApiService.postData(clientEndpoints.saveClientNotes, {}, payload);
    return response.data;
  },
);

export const downloadPaymentTransactionReport = (
  payload: IPaymentTransactionReportDownloadPayload,
) => {
  return ApiService.postData(clientEndpoints.downloadPaymentTransactions, {}, payload);
};

export const linkClientToUser = (payload: ILinkClientPayload[]) => {
  return ApiService.postData(peopleEndpoints.linkClientToUser, {}, payload);
};

export const unLinkClientToUser = (payload: IUnlinkClientPayload[]) => {
  return ApiService.postData(peopleEndpoints.unLinkClientFromUser, {}, payload);
};

export const ClientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
    resetStatus: (state) => {
      state.addClientStatus = 'idle';
      (state.addClientError = ''),
        (state.notesStatus = 'idle'),
        (state.notesError = ''),
        (state.setNewAccountOwnerStatus = 'idle');
      state.setNewAccountOwnerError = '';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(addClient.pending, (state) => {
        state.addClientStatus = 'loading';
      })
      .addCase(addClient.fulfilled, (state) => {
        state.addClientStatus = 'succeeded';
      })
      .addCase(addClient.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.addClientStatus = 'failed';
        state.addClientError =
          response?.error || response?.title || 'Something went wrong, please try again later';
      })
      .addCase(fetchClientDetails.pending, (state) => {
        state.clientDetailsStatus = 'loading';
        state.clientDetailsError = '';
      })
      .addCase(fetchClientDetails.fulfilled, (state, action) => {
        state.clientDetailsStatus = 'succeeded';
        state.clientDetails = action.payload.data;
      })
      .addCase(fetchClientDetails.rejected, (state) => {
        state.clientDetailsStatus = 'failed';
        state.clientDetailsError = 'failed to get client details';
      })
      .addCase(fetchNewAccountOwner.pending, (state) => {
        state.newAccountOwnerStatus = 'loading';
        state.newAccountOwnerError = '';
      })
      .addCase(fetchNewAccountOwner.fulfilled, (state, action) => {
        state.newAccountOwnerStatus = 'succeeded';
        state.newAccountOwner = action.payload.data;
      })
      .addCase(fetchNewAccountOwner.rejected, (state) => {
        state.newAccountOwnerStatus = 'failed';
        state.newAccountOwnerError = 'Failed to get new account owner details';
        state.newAccountOwner = null;
      })
      .addCase(setNewAccountOwner.pending, (state) => {
        state.setNewAccountOwnerStatus = 'loading';
        state.setNewAccountOwnerError = '';
      })
      .addCase(setNewAccountOwner.fulfilled, (state) => {
        state.setNewAccountOwnerStatus = 'succeeded';
        state.setNewAccountOwnerError = '';
      })
      .addCase(setNewAccountOwner.rejected, (state) => {
        state.setNewAccountOwnerStatus = 'failed';
        state.setNewAccountOwnerError = 'Failed to set new account owner';
      })
      .addCase(saveClientNotes.pending, (state) => {
        state.notesStatus = 'loading';
        state.notesError = '';
      })
      .addCase(saveClientNotes.fulfilled, (state) => {
        state.notesStatus = 'succeeded';
      })
      .addCase(saveClientNotes.rejected, (state, action) => {
        state.notesStatus = 'failed';
        state.notesError = action?.error?.message || 'Something went wrong, please try again later';
      });
  },
});

export const { updateFilters, resetStatus } = ClientSlice.actions;
export default ClientSlice.reducer;
