import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';

import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import SpectrumTextField from '../../components/Spectrum/TextField/SpectrumTextField';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { addClient, resetStatus } from '../../redux/clients/ClientSlice';
import { isValidEmail } from '../../utils/validation';
import { ColorPartial } from '@mui/material/styles/createPalette';

interface IAddUserDialog {
  isOpen: boolean;
  onClose: () => void;
}

const AddUserDialog = ({ isOpen, onClose }: IAddUserDialog) => {
  const [textValue, setTextValue] = useState('');
  const [error, setError] = useState(false);
  const dispatch: StoreDispatch = useDispatch();
  const { addClientError, addClientStatus, clientDetails } = useSelector(
    (state: RootState) => state.clientData,
  );
  const theme = useTheme();
  const white: ColorPartial = theme.palette.white as ColorPartial;

  const handleClose = () => {
    setTextValue('');
    setError(false);
    dispatch(resetStatus());
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setError(false);
    setTextValue(value);
  };

  const handleInvite = () => {
    if (!isValidEmail(textValue)) {
      setError(true);
    } else {
      dispatch(
        addClient({
          companyId: clientDetails?.id ?? 0,
          companyType: clientDetails?.clientType ?? '',
          email: textValue,
        }),
      );
    }
  };

  useEffect(() => {
    if (addClientStatus === 'succeeded') {
      handleClose();
    }
  }, [addClientStatus]);

  const displayError = (error: string) => (
    <Box my={theme.spacing(6)}>
      <Alert severity='error' variant='outlined'>
        <Typography variant='paragraph2' component='span' color='error'>
          {error}
        </Typography>
      </Alert>
    </Box>
  );

  const isLoading = addClientStatus === 'loading';

  return (
    <SpectrumDialog open={isOpen} onClose={handleClose}>
      <Box marginBottom={theme.spacing(8)}>
        <Typography variant='eyebrow' marginBottom={theme.spacing(4)}>
          Add User
        </Typography>
        <Typography variant='h4'>Add User to {clientDetails?.accountOwner}</Typography>
      </Box>
      {addClientStatus === 'failed' && displayError(addClientError)}
      <Typography variant='body1'>
        The new user will receive an email with an invitation to sign up for Client Portal and join
        Adero&apos;s team.
      </Typography>
      <Box marginY={theme.spacing(12)}>
        <SpectrumTextField
          label={'New User\'s Email Address'}
          value={textValue}
          onChange={handleChange}
          error={error}
          helperText={error && 'Please enter a valid email'}
        />
      </Box>
      <Box display='flex' gap={theme.spacing(4)}>
        <Button
          variant='contained'
          size='large'
          sx={{ borderRadius: theme.spacing(2) }}
          onClick={handleInvite}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress style={{ color: white[500], width: 30, height: 30 }} />
          ) : (
            'Invite User'
          )}
        </Button>
        <Button
          variant='text'
          size='large'
          startIcon={<Close />}
          sx={{ borderRadius: theme.spacing(2) }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </SpectrumDialog>
  );
};

export default AddUserDialog;
