import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { RootState, StoreDispatch } from '../../../redux/Store';
import { fetchReviewUserDetails } from '../../../redux/user/UserSlice';
import { formatDate, formatPhoneNumber, scrollToTop } from '../../../utils/common';
import { DATE_FORMAT } from '../../../constants/common';

import PageContentContainer from '../../../components/PageContentContainer/PageContentContainer';
import StatusCell from '../../../components/Spectrum/Table/StatusCell';
import { PageLoader } from '../../../components/Loaders/Loader';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import { Close, Email, Phone } from '@mui/icons-material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import BasicDetails from './BasicDetails';
import ConfigureUser from './ConfigureUser';
import DetailsConfirmation from './DetailsConfirmation';
import { StatusTypes } from '../../../redux/user/UserModel';

export interface INewClient {
  id: number;
  clientName: string;
  clientId: string;
  clientType: string;
}
export interface ISubmitMultiUserDetails {
  clients: INewClient[];
  ssoId: string;
}
export interface IUnlinkClientPayload {
  bpId: number;
  clientId: string;
  clientType: string;
}
export interface ILinkClientPayload {
  clientId: string;
  clientType: string;
  ssoId: string;
}

const ReviewDetails = () => {
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const navigate = useNavigate();
  const dispatch: StoreDispatch = useDispatch();

  const [dialogStatus, setDialogStatus] = useState(false);

  const { reviewUserDetails, reviewUserDetailsStatus, reviewUserDetailsError } = useSelector(
    (state: RootState) => state.userData,
  );

  const [configureUserDetails, setConfigureUserDetails] = useState<ISubmitMultiUserDetails>({
    clients: [],
    ssoId: '',
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const { userId } = useParams();

  useEffect(() => {
    if (userId) {
      dispatch(fetchReviewUserDetails(userId));
    }
  }, [userId]);

  useEffect(() => {
    setConfigureUserDetails({ ...configureUserDetails, ssoId: reviewUserDetails?.ssoId || '' });
  }, [reviewUserDetailsStatus]);

  const handleDialogClose = () => {
    setDialogStatus(false);
  };

  const displayError = (error: string) => (
    <Box my={theme.spacing(6)}>
      <Alert severity='error' variant='outlined'>
        <Typography variant='paragraph2' component='span' color='error'>
          {error}
        </Typography>
      </Alert>
    </Box>
  );

  const handleConfirm = () => {
    setDialogStatus(true);
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const isUpdateDetailsLoading = false;

  return (
    <PageContentContainer>
      <Button variant='text' onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
        Back to Users
      </Button>
      <Typography variant='h2'>Validate User</Typography>
      <Card variant='outlined' sx={{ p: theme.spacing(12), mt: theme.spacing(8) }}>
        {reviewUserDetailsStatus === 'loading' && <PageLoader />}
        {reviewUserDetailsStatus === 'failed' && displayError(reviewUserDetailsError)}
        {reviewUserDetails && (
          <>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Typography variant='h4'>
                  {reviewUserDetails?.firstName} {reviewUserDetails?.lastName}
                </Typography>
                <Box display='flex' gap={theme.spacing(4)} mb={4}>
                  {reviewUserDetails?.userName && (
                    <Typography variant='paragraph2'>@{reviewUserDetails.userName}</Typography>
                  )}
                </Box>
                <Link
                  href={`mailto:${reviewUserDetails.userEmail}`}
                  display='flex'
                  padding={theme.spacing(4)}
                  gap={theme.spacing(4)}
                  alignItems='center'
                >
                  <Email color='primary' fontSize='small' />
                  <Typography
                    variant='body1'
                    color={black[500]}
                    sx={{ textDecoration: 'underline' }}
                  >
                    {reviewUserDetails?.userEmail}
                  </Typography>
                </Link>
                <Box
                  display='flex'
                  padding={theme.spacing(4)}
                  gap={theme.spacing(4)}
                  alignItems='center'
                >
                  <Phone color='primary' fontSize='small' />
                  <Typography variant='body1' color={black[500]}>
                    {reviewUserDetails?.userPhone && formatPhoneNumber(reviewUserDetails.userPhone)}
                  </Typography>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gap={theme.spacing(8)}
                alignItems='flex-end'
              >
                <StatusCell status={StatusTypes.NeedsReview} />
                {reviewUserDetails.createdDate && (
                  <Typography fontSize='0.7rem' fontStyle='italic'>
                    Created {formatDate(reviewUserDetails.createdDate, DATE_FORMAT)}
                  </Typography>
                )}
              </Box>
            </Box>
            <Grid container spacing={12} mt={theme.spacing(4)}>
              <Grid item mobile={6}>
                <BasicDetails userDetails={reviewUserDetails} />
              </Grid>
              <Grid item mobile={6}>
                <ConfigureUser
                  reviewUserDetails={reviewUserDetails}
                  updateConfigureUserDetails={setConfigureUserDetails}
                  configureUserDetails={configureUserDetails}
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 10, mb: 10 }} />
            <Box display='flex' gap={theme.spacing(10)} mt={theme.spacing(8)}>
              <Button
                variant='contained'
                onClick={handleConfirm}
                sx={{
                  borderRadius: theme.spacing(2),
                  width: 'fit-content',
                }}
                disabled={!configureUserDetails?.clients?.length}
              >
                {isUpdateDetailsLoading && (
                  <CircularProgress size={18} sx={{ color: white[500], mr: 5 }} />
                )}
                Confirm User
              </Button>
              <Button startIcon={<Close />} onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Card>
      <SpectrumDialog open={dialogStatus} onClose={handleDialogClose}>
        <DetailsConfirmation onClose={handleDialogClose} confirmDetails={configureUserDetails} />
      </SpectrumDialog>
    </PageContentContainer>
  );
};

export default ReviewDetails;
