import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import BasicDetails from './BasicDetails';
import { Add } from '@mui/icons-material';
import { PageLoader } from '../../components/Loaders/Loader';
import { RootState, StoreDispatch } from '../../redux/Store';
import { formatDate } from '../../utils/common';
import { DATE_FORMAT } from '../../constants/common';
import { fetchClientDetails } from '../../redux/clients/ClientSlice';

import ChangeAccountOwnerDialog from './ChangeAccountOwnerDialog';
import AddUserDialog from './AddUserDialog';
import TransactionReport from './TransactionReport';
import { appConstants } from '../../constants/apiConstants';

interface IClientProfile {
  ssoId: string;
  bpId: number;
}

const ClientProfile = ({ ssoId, bpId }: IClientProfile) => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const { clientDetails, clientDetailsStatus, clientDetailsError } = useSelector(
    (state: RootState) => state.clientData,
  );

  const [dialogToOpen, setDialogToOpen] = useState('');

  const handleAddUser = () => {
    setDialogToOpen((prv) => (prv === '' ? 'AddUser' : ''));
  };

  const handleExportTransactionReport = () => {
    setDialogToOpen((prv) => (prv === '' ? 'ExportTransactionReport' : ''));
  };

  const handleChangeAccountOwner = () => {
    setDialogToOpen((prv) => (prv === '' ? 'ChangeAccountOwner' : ''));
  };

  const handleViewClient = () => {
    window.open(appConstants.sapURL, '_blank');
  };

  useEffect(() => {
    dispatch(fetchClientDetails({ ssoId, bpId }));
  }, []);

  const displayError = (error: string) => (
    <Box my={theme.spacing(6)}>
      <Alert severity='error' variant='outlined'>
        <Typography variant='paragraph2' component='span' color='error'>
          {error}
        </Typography>
      </Alert>
    </Box>
  );

  if (clientDetailsStatus === 'loading') return <PageLoader />;

  if (clientDetailsStatus === 'failed') return displayError(clientDetailsError);

  return (
    clientDetails && (
      <Container>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Typography variant='h1'>{clientDetails?.client}</Typography>
            <Box display='flex' gap={theme.spacing(4)}>
              {clientDetails?.accountOwnerUserName && (
                <Typography variant='paragraph2'>@{clientDetails.clientId}</Typography>
              )}
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' gap={theme.spacing(8)} alignItems='flex-end'>
            <StatusCell status={clientDetails.status} />
            {clientDetails.createdDate && (
              <Typography fontSize='0.7rem' fontStyle='italic'>
                Created {formatDate(clientDetails.createdDate, DATE_FORMAT)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box mt={theme.spacing(12)}>
          <Grid container spacing={theme.spacing(6)} mt={theme.spacing(12)}>
            <Grid item laptop={9.5}>
              <BasicDetails clientDetails={clientDetails} />
            </Grid>
            <Grid item laptop={2.5}>
              <Box display='flex' flexDirection='column' gap={theme.spacing(4)}>
                <Button
                  variant='contained'
                  startIcon={<Add />}
                  sx={{ borderRadius: theme.spacing(2) }}
                  onClick={handleAddUser}
                  size='small'
                >
                  Add User
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{ borderRadius: theme.spacing(2) }}
                  onClick={handleExportTransactionReport}
                >
                  Export Transaction Report
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{ borderRadius: theme.spacing(2) }}
                  onClick={handleChangeAccountOwner}
                >
                  Change Account Owner
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{ borderRadius: theme.spacing(2) }}
                  onClick={handleViewClient}
                >
                  View Client in SAP
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ChangeAccountOwnerDialog
          isOpen={dialogToOpen === 'ChangeAccountOwner'}
          onClose={handleChangeAccountOwner}
        />
        <AddUserDialog isOpen={dialogToOpen === 'AddUser'} onClose={handleAddUser} />
        <TransactionReport
          isOpen={dialogToOpen === 'ExportTransactionReport'}
          onClose={handleExportTransactionReport}
          bpId={clientDetails?.accountOwnerBpId}
        />
      </Container>
    )
  );
};

export default ClientProfile;
