import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import { ClientTypes } from '../../../redux/clients/ClientModel';
import { ColorPartial } from '@mui/material/styles/createPalette';
import {
  IAdvertiser,
  IClientDetails,
  IErrorLocalUserDetails,
  ILocalUserDetails,
  IUser,
  IUserRequestPayload,
  Roles,
} from '../../../redux/user/UserModel';
import UnlinkClientModal from './UnlinkClientModal';
import AccountAccessSelection from '../../People/AccountAccessSelection';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import RoleSelection from '../../People/RoleSelection';
import ReportsToUserSelection from '../../People/ReportsToUserSelection';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { Close, Edit } from '@mui/icons-material';
import { fetchRegisteredUserDetails, updateUserDetails } from '../../../redux/user/UserSlice';
import { formatErrors } from '../../../utils/common';
import { grey } from '@mui/material/colors';
import UnLinkIcon from '@mui/icons-material/LinkOff';
import { IUnlinkClientPayload } from '../ReviewDetails/ReviewDetails';
import { unLinkClientToUser } from '../../../redux/clients/ClientSlice';

interface IEditDetailsProps {
  userDetails: IUser;
  userClientDetails: IClientDetails;
  reloadUserDetails: () => void;
}

const EditDetails = ({ userDetails, userClientDetails, reloadUserDetails }: IEditDetailsProps) => {
  const dispatch: StoreDispatch = useDispatch();

  const theme = useTheme();
  const white: ColorPartial = theme.palette.white as ColorPartial;

  const {
    reportsToUsersStatus,
    reportsToUsers,
    userRolesStatusError,
    agencyListError,
    advertiserListError,
    agencyAdvertiserListStatusError,
    reportsToUsersStatusError,
    selectedClientDetailsResponse,
    selectedClientDetailsStatus,
  } = useSelector((state: RootState) => state.userData);

  const [isEditMode, setIsEditMode] = useState(false);

  const [clientUnLinkStatus, setClientUnLinkStatus] = useState({ loading: false, error: '' });

  const [error, setError] = useState('');
  const [updateDetailsStatus, setUpdateDetailsStatus] = useState('idle');
  const [showUnlinkClientModal, setShowUnlinkClientModal] = useState(false);

  const [localUserDetails, setLocalUserDetails] = useState<ILocalUserDetails>({
    companyType: '' as ClientTypes,
    client: '',
    roleId: '',
    reportsToBpId: null,
    advertisers: [],
  });

  const handleEdit = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    if (selectedClientDetailsStatus === 'succeeded') {
      if (selectedClientDetailsResponse.clientType === ClientTypes.Agency) {
        setLocalUserDetails({
          ...localUserDetails,
          companyType: ClientTypes.Agency,
          client: {
            id: selectedClientDetailsResponse.id,
            agencyId: selectedClientDetailsResponse.clientId,
            agencyName: selectedClientDetailsResponse.clientName,
          },
        });
      } else {
        setLocalUserDetails({
          ...localUserDetails,
          companyType: ClientTypes.Advertiser,
          client: {
            id: selectedClientDetailsResponse.id,
            advertiserId: selectedClientDetailsResponse.clientId,
            advertiserName: selectedClientDetailsResponse.clientName,
          },
        });
      }
    }
  }, [selectedClientDetailsStatus]);

  const [errorUserDetails, setErrorUserDetails] = useState<IErrorLocalUserDetails>({
    companyType: '',
    client: '',
    roleId: '',
    reportsToBpId: '',
    advertisers: '',
  });

  const isAgency = localUserDetails?.companyType === ClientTypes.Agency;
  const companyId = localUserDetails?.client?.id;

  // If client type is agency we pick advertisers list based on that agency
  const { agencyAdvertiserList } = useSelector((state: RootState) => state.userData);

  // If client type is advertiser itself, then we just show selected advertiser as only one account in advertisers
  const advertiserAdvertiserList = localUserDetails?.client ? [localUserDetails?.client] : [];

  const transformedAdvertiserList =
    isAgency && companyId
      ? (agencyAdvertiserList || []).map((advertiser) => {
          return advertiser.tblAdvertiser;
        })
      : advertiserAdvertiserList;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value: any = event.target.value;
    const updatedLocalUserDetails = { ...localUserDetails };
    if (name === 'companyType') {
      updatedLocalUserDetails.client = null;
      updatedLocalUserDetails.advertisers = [];
      updatedLocalUserDetails.reportsToBpId = null;
      updatedLocalUserDetails.roleId = '';
    }
    if (name === 'client') {
      updatedLocalUserDetails.reportsToBpId = null;
      updatedLocalUserDetails.roleId = '';
      updatedLocalUserDetails.advertisers = [];
    }
    setLocalUserDetails({ ...updatedLocalUserDetails, [name]: value });
    setErrorUserDetails({ ...errorUserDetails, [name]: '' });
  };

  const handleAccountSelection = (event: any, advertiserData: IAdvertiser) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      setLocalUserDetails((prevState: any) => ({
        ...prevState,
        advertisers: advertiserData
          ? [...(prevState.advertisers || []), advertiserData]
          : transformedAdvertiserList,
      }));
    } else {
      setLocalUserDetails((prevState: any) => ({
        ...prevState,
        advertisers: advertiserData
          ? prevState.advertisers?.filter(
              (advertiser: IAdvertiser) => advertiser.advertiserId !== advertiserData.advertiserId,
            )
          : [],
      }));
    }
  };

  useEffect(() => {
    setLocalUserDetails({
      ...localUserDetails,
      companyType: (userClientDetails?.companyType || '') as ClientTypes,
      client:
        userClientDetails?.companyType === ClientTypes.Agency
          ? {
              id: userClientDetails?.companyId,
              agencyId: userClientDetails?.clientId,
              agencyName: userClientDetails?.client,
            }
          : {
              id: userClientDetails?.companyId,
              advertiserId: userClientDetails?.clientId,
              advertiserName: userClientDetails?.client,
            },
      advertisers: userClientDetails?.advertisers,
      roleId: userClientDetails?.role?.id,
      reportsToBpId: userClientDetails?.reportsToBpId,
    });
  }, []);

  const isLoading = reportsToUsersStatus === 'loading';
  const isUpdateDetailsLoading = updateDetailsStatus === 'loading';

  useEffect(() => {
    // If report to users empty, user can only be account owner
    if (
      reportsToUsersStatus === 'succeeded' &&
      reportsToUsers?.length === 0 &&
      localUserDetails?.client
    ) {
      setLocalUserDetails({ ...localUserDetails, roleId: Roles.ACCOUNT_OWNER });
    }
  }, [reportsToUsersStatus]);

  const validateFields = () => {
    const errorDetails = { ...errorUserDetails };
    let isValid = true;
    if (!localUserDetails?.companyType) {
      errorDetails.companyType = 'Please select client type';
      isValid = false;
    }
    if (!localUserDetails?.client?.id) {
      errorDetails.client = 'Please select client';
      isValid = false;
    }
    if (!localUserDetails?.roleId) {
      errorDetails.roleId = 'Please select role';
      isValid = false;
    }
    if (localUserDetails?.roleId !== Roles.ACCOUNT_OWNER && !localUserDetails?.reportsToBpId) {
      errorDetails.reportsToBpId = 'Please select reports to';
      isValid = false;
    }
    if (
      localUserDetails?.roleId === Roles.ACCOUNT_OWNER &&
      !localUserDetails?.advertisers?.length
    ) {
      errorDetails.advertisers =
        'Please select all accounts, account owner should have access to all accounts';
      isValid = false;
    }
    return { isValid, errorDetails };
  };

  const handleConfirm = () => {
    const { isValid, errorDetails } = validateFields();
    if (isValid) {
      setError('');
      setUpdateDetailsStatus('loading');
      const payload: IUserRequestPayload = {
        advertisers: localUserDetails?.advertisers,
        clientId: localUserDetails?.client?.id,
        clientType: localUserDetails?.companyType,
        reportsToBpId: localUserDetails?.reportsToBpId,
        roleId: localUserDetails?.roleId,
        notes: userDetails?.userNotes,
        ssoId: userDetails?.ssoId,
        bpId: userClientDetails.bpId
      };
      updateUserDetails(payload)
        .then(() => {
          // setIsEditMode(false);
          setUpdateDetailsStatus('succeeded');
          dispatch(fetchRegisteredUserDetails(payload?.ssoId));
        })
        .catch((error) => {
          setUpdateDetailsStatus('failed');
          const errorMessage = error?.errors ? formatErrors(error?.errors) : error?.error;
          setError(errorMessage || 'Something went wrong, Failed to update user details');
        });
    } else {
      setErrorUserDetails(errorDetails);
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const displayError = (error: string) => (
    <Box my={theme.spacing(6)}>
      <Alert severity='error' variant='outlined'>
        <Typography variant='paragraph2' component='span' color='error'>
          {error}
        </Typography>
      </Alert>
    </Box>
  );

  const handleShowUnlinkModal = () => {
    setShowUnlinkClientModal(true);
  };

  const handleCloseUnlinkModal = () => {
    setShowUnlinkClientModal(false);
  };

  const handleUnlinkClient = () => {
    setShowUnlinkClientModal(false);
    const payload: IUnlinkClientPayload = {
      bpId: userClientDetails.bpId,
      clientId: userClientDetails.clientId,
      clientType: userClientDetails.companyType,
    };
    setClientUnLinkStatus({ loading: true, error: '' });
    unLinkClientToUser([payload])
      .then(() => {
        setClientUnLinkStatus({ loading: false, error: '' });
        reloadUserDetails();
      })
      .catch((error) => {
        setClientUnLinkStatus({ loading: false, error: error.error });
      });
  };

  const disableConfirm =
    !localUserDetails.client ||
    !localUserDetails.client.id ||
    isLoading ||
    !localUserDetails.roleId;

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      {isLoading && <ComponentOverlayLoader />}
      {userRolesStatusError && displayError(userRolesStatusError)}
      {agencyListError && displayError(agencyListError)}
      {advertiserListError && displayError(advertiserListError)}
      {agencyAdvertiserListStatusError && displayError(agencyAdvertiserListStatusError)}
      {reportsToUsersStatusError && displayError(reportsToUsersStatusError)}
      <Box>
        <Box mb={10}>
          <RoleSelection
            selectedReportsToSSOId={localUserDetails?.reportsToBpId}
            roleId={localUserDetails?.roleId}
            handleChange={handleChange}
            error={errorUserDetails?.roleId}
            isDisabled={!isEditMode}
          />
        </Box>
        <Box mb={10}>
          <ReportsToUserSelection
            companyType={localUserDetails?.companyType}
            selectedRoleId={localUserDetails?.roleId}
            companyId={companyId}
            reportsToSSOId={localUserDetails?.reportsToBpId}
            handleChange={handleChange}
            error={errorUserDetails?.reportsToBpId}
            isDisabled={!isEditMode}
          />
        </Box>
        <Box>
          <Typography variant='h5' mb={12}>
            Account Access
          </Typography>
          <AccountAccessSelection
            companyId={companyId}
            roleId={localUserDetails?.roleId}
            selectedAdvertisers={localUserDetails.advertisers || []}
            advertiserList={transformedAdvertiserList}
            handleChange={handleAccountSelection}
            error={errorUserDetails?.advertisers}
            isDisabled={!isEditMode}
          />
        </Box>
        <Divider sx={{ borderColor: grey[300], mt: 10, mb: 10 }} />
      </Box>
      {error && (
        <Box mt={5} mb={5}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      <UnlinkClientModal
        open={showUnlinkClientModal}
        handleClose={handleCloseUnlinkModal}
        handleUnlink={handleUnlinkClient}
        clientName={userClientDetails.client || ''}
      />
      {clientUnLinkStatus?.error && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {clientUnLinkStatus?.error}
            </Typography>
          </Alert>
        </Box>
      )}
      <Box
        display='flex'
        mt={theme.spacing(8)}
        mb={theme.spacing(8)}
        justifyContent='space-between'
      >
        <Box display='flex' gap={theme.spacing(10)}>
          {isEditMode ? (
            <>
              <Button
                size='small'
                variant='contained'
                sx={{
                  borderRadius: theme.spacing(2),
                }}
                onClick={handleConfirm}
                disabled={disableConfirm}
              >
                {isUpdateDetailsLoading && (
                  <CircularProgress size={18} sx={{ color: white[500], mr: 5 }} />
                )}
                Confirm Changes
              </Button>
              <Button startIcon={<Close />} onClick={handleCancel}>
                Cancel
              </Button>
            </>
          ) : (
            <Button
              size='small'
              variant='outlined'
              startIcon={<Edit />}
              sx={{
                borderRadius: theme.spacing(2),
                width: 'fit-content',
                p: 4,
              }}
              onClick={handleEdit}
            >
              Edit Details
            </Button>
          )}
        </Box>
        <Button onClick={handleShowUnlinkModal}>
          {clientUnLinkStatus.loading ? (
            <CircularProgress size={16} sx={{ mr: 8 }} />
          ) : (
            <UnLinkIcon color='primary' sx={{ pr: 2 }} />
          )}{' '}
          Unlink Client
        </Button>
      </Box>
    </Box>
  );
};
export default EditDetails;
