import { Box, styled } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledReportContainer = styled('div')(({ theme }) => {
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    width: '175px',
    cursor: 'pointer',
    '.report-icon': {
      padding: theme.spacing(0, 4),
      background: blue[500],
      color: white[500],
      borderRadius: theme.spacing(2, 0, 0, 2),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      svg: {
        fontSize: '1.2rem',
      },
    },
    '.report-label': {
      padding: theme.spacing(0, 8, 0, 4),
      background: blue[100],
      color: blue[500],
      fontSize: '13px',
      fontWeight: 600,
      borderRadius: theme.spacing(0, 2, 2, 0),
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '.icon': {
        color: grey[500],
      },
    },
  };
});
export const StyledReportDownloadLink = styled('div')(({ theme }) => {
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  return {
    display: 'flex',
    alignItems: 'center',
    height: '70px',
    cursor: 'pointer',
    background: blue[100],
    padding: theme.spacing(0, 8, 0, 4),
    borderRadius: theme.spacing(0, 2, 2, 0),
    '.report-icon': {
      color: blue[500],
      paddingRight: theme.spacing(5),
    },
    '.report-label': {
      flexGrow: 1,
    },
  };
});
export const StyledReportCalendarContainer = styled('div')(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return {
    '.react-datepicker': {
      boxShadow: 'none',
      border: `1px solid ${grey[400]}`,
    },
  };
});

export const StyledReportSelect = styled(Box)(({ theme }) => {
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  return {
    padding: theme.spacing(8),
    margin: theme.spacing(10, 0, 0,0),
    backgroundColor: blue[100]
  };
})
