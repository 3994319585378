import React from 'react';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import TransactionsTable from './TransactionsTable';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import TransactionNewFilters from './TransactionNewFilters';
import TransactionAdvFilters from './TransactionAdvFilters';

const Transactions = () => {
  const theme = useTheme();
  const [filtersStatus, setFiltersStatus] = React.useState<boolean>(false);
  const handleToggleFilters = () => {
    setFiltersStatus(!filtersStatus);
  };
  return (
    <PageContentContainer>
      <Typography mb={theme.spacing(12)} variant='h1'>
        Transactions
      </Typography>
      <Grid container spacing={14}>
        <Grid item laptop={filtersStatus ? 9 : 12}>
          <Box sx={{ mb: 6 }}>
            <TransactionNewFilters filtersStatus={filtersStatus} toggleFilters={handleToggleFilters} />
          </Box>
          <TransactionsTable />
        </Grid>
        {filtersStatus && (
          <Grid item laptop={3}>
            <TransactionAdvFilters toggleFilters={handleToggleFilters} />
          </Grid>
        )}
      </Grid>
    </PageContentContainer>
  );
};

export default Transactions;
