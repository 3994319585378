import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  CircularProgress,
  InputAdornment,
  Box,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// Define the props type
interface AsyncAutocompleteProps {
  fetchOptions: (query: string) => Promise<any[]>;
  onSelect?: (value: any) => void;
  label?: string;
  value?: any;
}

const AsyncAutocomplete: React.FC<AsyncAutocompleteProps> = ({
  fetchOptions,
  onSelect,
  label = 'Search',
  value,
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (!inputValue.trim() || value) {
      setOptions([]);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const results = await fetchOptions(inputValue); // Parent fetch logic
        setOptions(results || []);
      } catch (error) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    };

    const debounceFetch = setTimeout(fetchData, 500);
    return () => clearTimeout(debounceFetch);
  }, [inputValue]);

  const handleSelect = (value: any) => {
    onSelect?.(value);
  };

  return (
    <Autocomplete
      options={options}
      filterOptions={(x) => x}
      onInputChange={(_, value, reason) => {
        if (reason !== 'clear') {
          setInputValue(value);
        }
      }}
      value={value}
      onChange={(event, value) => handleSelect(value)}
      clearIcon={false}
      inputValue={inputValue}
      loading={loading}
      getOptionKey={(option) => option?.id}
      getOptionLabel={(option) => (option ? option?.name + '  #' + option?.id : '')}
      renderOption={(props: any, option) => {
        return (
          <li {...props} key={props.key}>
            <Box display='flex'>
              <Typography variant='h6' mr={3}>
                {option.name}
              </Typography>{' '}
              #{option.id}
            </Box>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='outlined'
          size='medium'
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsyncAutocomplete;
