import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Email, ExpandCircleDownOutlined, Phone } from '@mui/icons-material';
import { IUser } from '../../redux/user/UserModel';

import { formatPhoneNumber } from '../../utils/common';
import UserNotes from './UserNotes';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '../Transactions/EditTransaction/EditTransactionStyles';
import { grey } from '@mui/material/colors';
import EditDetails from './EditDetails/EditDetails';
import { ILinkClientPayload, ISubmitMultiUserDetails } from './ReviewDetails/ReviewDetails';
import AddIcon from '@mui/icons-material/Add';
import ClientSelectionModal from './ReviewDetails/ClientSelectionModal';
import { linkClientToUser } from '../../redux/clients/ClientSlice';

interface IBasicDetailsProps {
  userDetails: IUser;
  reloadUserDetails: () => void;
}

const BasicDetails = ({ userDetails, reloadUserDetails }: IBasicDetailsProps) => {
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;

  const [clientLinkStatus, setClientLinkStatus] = useState({ loading: false, error: '' });

  const [linkClientModalStatus, setLinkClientModalStatus] = useState(false);

  const handleLinkClientModalStatusOpen = () => {
    setLinkClientModalStatus(true);
  };

  const handleLinkClientModalStatusClose = () => {
    setLinkClientModalStatus(false);
  };

  const onLinkNewClients = (data: ISubmitMultiUserDetails) => {
    const payload: ILinkClientPayload[] = data.clients.map((client) => ({
      clientType: client.clientType,
      clientId: client.clientId,
      ssoId: userDetails.ssoId,
    }));
    setClientLinkStatus({ loading: true, error: '' });
    linkClientToUser(payload)
      .then((result) => {
        setClientLinkStatus({ loading: false, error: '' });
        reloadUserDetails();
      })
      .catch((error) => {
        setClientLinkStatus({ loading: false, error: error.title });
      });
  };

  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <Box sx={{ background: blue[100], border: `1px solid ${blue[200]}`, p: 10 }}>
        <Typography variant='h4' mb={12}>
          User Details
        </Typography>
        <Box>
          <Typography color={black[500]} variant='eyebrow'>
            Contact Information
          </Typography>
          <Link
            href={`mailto:${userDetails.userEmail}`}
            display='flex'
            padding={theme.spacing(4)}
            gap={theme.spacing(4)}
            alignItems='center'
          >
            <Email color='primary' fontSize='small' />
            <Typography variant='body1' color={black[500]} sx={{ textDecoration: 'underline' }}>
              {userDetails?.userEmail}
            </Typography>
          </Link>
          <Box display='flex' padding={theme.spacing(4)} gap={theme.spacing(4)} alignItems='center'>
            <Phone color='primary' fontSize='small' />
            <Typography variant='body1' color={black[500]}>
              {userDetails?.userPhone && formatPhoneNumber(userDetails.userPhone)}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ borderColor: primary[100], mt: 10, mb: 10 }} />
        <Box>
          <Box display='flex' alignItems='center' justifyContent='space-between' mb={12}>
            <Typography variant='h4'>Clients</Typography>
            <Button
              onClick={handleLinkClientModalStatusOpen}
              variant='outlined'
              sx={{ borderRadius: 2 }}
              disabled={clientLinkStatus.loading}
            >
              {clientLinkStatus.loading ? (
                <CircularProgress size={16} sx={{ mr: 8 }} />
              ) : (
                <AddIcon color='primary' sx={{ pr: 2 }} />
              )}{' '}
              Link to Client
            </Button>
          </Box>
          {clientLinkStatus?.error && (
            <Box mb={theme.spacing(12)}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {clientLinkStatus?.error}
                </Typography>
              </Alert>
            </Box>
          )}
          {userDetails.clients.map((client) => {
            return (
              <StyledAccordion key={client.clientId} TransitionProps={{ unmountOnExit: true }}>
                <StyledAccordionSummary expandIcon={<ExpandCircleDownOutlined color='primary' />}>
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography variant='h6'>{client.client}</Typography>
                    <Typography variant='paragraph2' ml={4} mr={2}>
                      #{client.clientId}
                    </Typography>
                    <Chip
                      label={client?.companyType}
                      color='purple'
                      size='small'
                      sx={{ ml: 4, borderRadius: 0 }}
                    />
                  </Box>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                  <Box
                    display='flex'
                    flexDirection='column'
                    gap={theme.spacing(12)}
                    mt={theme.spacing(8)}
                  >
                    <Divider sx={{ borderColor: grey[300] }} />
                    <EditDetails
                      userDetails={userDetails}
                      userClientDetails={client}
                      reloadUserDetails={reloadUserDetails}
                    />
                  </Box>
                </StyledAccordionDetails>
              </StyledAccordion>
            );
          })}
        </Box>
      </Box>
      <ClientSelectionModal
        open={linkClientModalStatus}
        handleClose={handleLinkClientModalStatusClose}
        updateConfigureUserDetails={onLinkNewClients}
        configureUserDetails={{} as ISubmitMultiUserDetails}
      />
      <UserNotes userDetails={userDetails} />
    </Card>
  );
};

export default BasicDetails;
