import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { IUser } from '../../redux/user/UserModel';
import { fetchRegisteredUserDetails, updateUserDetails } from '../../redux/user/UserSlice';
import { StoreDispatch } from '../../redux/Store';
import { useDispatch } from 'react-redux';
import { formatErrors } from '../../utils/common';
import { USER_STATUS } from '../../constants/common';

interface IUserNotesProps {
  userDetails: IUser;
}

const UserNotes = ({ userDetails }: IUserNotesProps) => {
  const dispatch: StoreDispatch = useDispatch();

  const theme = useTheme();
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;

  const isActive = userDetails?.status === USER_STATUS.Active;

  const [error, setError] = useState('');
  const [updateDetailsStatus, setUpdateDetailsStatus] = useState('idle');

  const [isEditMode, setIsEditMode] = useState(false);
  const [localUserDetails, setLocalUserDetails] = useState({ ...userDetails });

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    setLocalUserDetails((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
    setError('');
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setError('');
  };

  const handleConfirm = () => {
    setError('');
    setUpdateDetailsStatus('loading');
    const payload: any = {
      advertisers: [],
      clientId: 0,
      clientType: '',
      reportsToBpId: 0,
      roleId: 0,
      ssoId: userDetails.ssoId,
      bpId: 0,
      notes: localUserDetails?.userNotes,
    };
    updateUserDetails(payload)
      .then(() => {
        setIsEditMode(false);
        setUpdateDetailsStatus('succeeded');
        dispatch(fetchRegisteredUserDetails(payload?.ssoId));
      })
      .catch((error) => {
        setUpdateDetailsStatus('failed');
        const errorMessage = error?.errors ? formatErrors(error?.errors) : error?.error;
        setError(errorMessage || 'Something went wrong, Failed to update user notes');
      });
  };

  const isUpdateDetailsLoading = updateDetailsStatus === 'loading';

  return (
    <Box sx={{ background: blue[100], border: `1px solid ${blue[200]}`, p: 10, mt: 12 }}>
      <Box display='flex' flexDirection='column' gap={theme.spacing(8)} padding='10' margin={0}>
        <Typography variant='h4'>User Notes</Typography>
        {isEditMode ? (
          <TextField
            id='user-notes'
            label=''
            multiline
            rows={4}
            name='userNotes'
            value={localUserDetails.userNotes}
            onChange={handleChange}
            sx={{ backgroundColor: white[500] }}
          />
        ) : (
          <Typography variant='body1'>
            <pre style={{ fontFamily: 'inherit', margin: 0, whiteSpace: 'inherit' }}>
              {userDetails?.userNotes}
            </pre>
          </Typography>
        )}
      </Box>
      <Divider sx={{ mt: 10, borderColor: primary[100] }} />
      {error && (
        <Box mt={5} mb={5}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      {isEditMode ? (
        <Box display='flex' gap={theme.spacing(4)} mt={theme.spacing(8)}>
          <Button
            size='medium'
            variant='contained'
            sx={{
              borderRadius: theme.spacing(2),
              width: 'fit-content',
            }}
            onClick={handleConfirm}
          >
            {isUpdateDetailsLoading && (
              <CircularProgress size={18} sx={{ color: white[500], mr: 5 }} />
            )}
            Save Changes
          </Button>
          <Button startIcon={<Close />} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      ) : (
        <Button
          size='medium'
          variant='outlined'
          startIcon={<Edit />}
          sx={{
            borderRadius: theme.spacing(2),
            mt: theme.spacing(8),
            width: 'fit-content',
          }}
          disabled={!isActive}
          onClick={handleEdit}
        >
          Edit Details
        </Button>
      )}
    </Box>
  );
};
export default UserNotes;
