export const appConstants = {
  callbackURL: process.env.REACT_APP_CALLBACK_URL || '',
  adminPortalAPI: process.env.REACT_APP_API_ADMIN_URL || '',
  anacabURL: process.env.REACT_APP_ANACAB_URL || '',
  sapURL: process.env.REACT_APP_SAP_URL || ''
};

export const ssoConfig = {
  ssoURL: `${process.env.REACT_APP_SSO_URL}`,
  clientId: process.env.REACT_APP_SSO_CLIENT_ID || '',
  scope: process.env.REACT_APP_SSO_SCOPE || '',
};

export const authApiEndPoints = {
  loginURL: `${ssoConfig.ssoURL}/oauth2/authorize?client_id=${ssoConfig.clientId}&response_type=code&scope=openid&redirect_uri=${appConstants.callbackURL}`,
  tokenURL: `${ssoConfig.ssoURL}/oauth2/token`,
};

export const adPortalTransactionEndpoints = {
  list: `${appConstants.adminPortalAPI}/api/AdPortal/GetAdPortalTransactions`,
  export: `${appConstants.adminPortalAPI}/api/AdPortal/DownloadAdportalTransactions`,
};
export const transactionEndpoints = {
  list: `${appConstants.adminPortalAPI}/api/Transactions/GetPaymentTransactions`,
  paymentStatusList: `${appConstants.adminPortalAPI}/api/Transactions/GetPaymentStatusList`,
  transactionDetails: `${appConstants.adminPortalAPI}/api/Transactions/GetSchedulePaymentDetails`,
  downloadTransactionsHistoryURL: `${appConstants.adminPortalAPI}/api/Reports/ClientPortalPayments`,
  downloadAdportalTransactionsHistoryURL: `${appConstants.adminPortalAPI}/api/Reports/DownloadAdportalTransactions`,
  downloadUsersReportURL: `${appConstants.adminPortalAPI}/api/Reports/DownloadUserReport`,
  downloadRecurringPaymentsReportURL: `${appConstants.adminPortalAPI}/api/Reports/DownloadRecurringPaymentReport`,
  cancelSchedulePaymentURL: `${appConstants.adminPortalAPI}/api/Transactions/CancelScheduledPayment`,
  changePaymentStatusURL: `${appConstants.adminPortalAPI}/api/Client/UpdatePaymentStatus`,
};

export const peopleEndpoints = {
  list: `${appConstants.adminPortalAPI}/api/People/GetRegisteredUsers`,
  userStatusList: `${appConstants.adminPortalAPI}/api/People/GetUserStatus`,
  agencyAdvertisers: `${appConstants.adminPortalAPI}/api/AgencyAdvertiser/GetAdvertiserForAgency`,
  detail: `${appConstants.adminPortalAPI}/api/People/GetSelectedUserDetails`,
  reviewUserDetailsURL: `${appConstants.adminPortalAPI}/api/People/GetUserRegistrationReviewDetails`,
  confirmReviewUserDetailsURL: `${appConstants.adminPortalAPI}/api/People/ConfirmReviewUser`,
  updateDetails: `${appConstants.adminPortalAPI}/api/People/UpdateUser`,
  linkClientToUser: `${appConstants.adminPortalAPI}/api/People/LinkBpToUser`,
  unLinkClientFromUser: `${appConstants.adminPortalAPI}/api/People/UnLinkBpFromUser`,
  export: `${appConstants.adminPortalAPI}/api/People/DownloadRegisteredUsers`,
  recurringPayments: `${appConstants.adminPortalAPI}/api/People/GetRecurringPayments`,
  cancelRecurringPayments: `${appConstants.adminPortalAPI}/api/People/CancelAutoPayment`,
  remove: `${appConstants.adminPortalAPI}/api/People/RemoveRegisteredUser`,
  activateUserURL: `${appConstants.adminPortalAPI}/api/People/ActivateUser`,
  deactivateUserURL: `${appConstants.adminPortalAPI}/api/People/DeactivateUser`,
  resetPassword: `${appConstants.adminPortalAPI}/api/People/ResetPassword`,
  reportsToUsers: `${appConstants.adminPortalAPI}/api/People/GetReportsToUsers`,
  userRoles: `${appConstants.adminPortalAPI}/api/People/GetUserRoles`,
  selectedClientDetails: `${appConstants.adminPortalAPI}/api/People/GetSelectedClientDetails`,
  agenciesList: `${appConstants.adminPortalAPI}/api/AgencyAdvertiser/GetAgencies`,
  advertisersList: `${appConstants.adminPortalAPI}/api/AgencyAdvertiser/GetAdvertisers`,
}

export const clientEndpoints = {
  list: `${appConstants.adminPortalAPI}/api/Client/GetClients`,
  people: `${appConstants.adminPortalAPI}/api/Client/GetPeopleDetails`,
  recurringPayments: `${appConstants.adminPortalAPI}/api/Client/GetRecurringPayments`,
  cancelRecurringPayments: `${appConstants.adminPortalAPI}/api/Client/CancelAutoPayment`,
  transactions: `${appConstants.adminPortalAPI}/api/Client/GetPaymentTransactions`,
  addUser: `${appConstants.adminPortalAPI}/api/Client/AddUser`,
  detail: `${appConstants.adminPortalAPI}/api/Client/GetClientDetail`,
  newAccountOwner: `${appConstants.adminPortalAPI}/api/Client/GetNewAccountOwner`,
  setNewAccountOwner: `${appConstants.adminPortalAPI}/api/Client/SetNewAccountOwner`,
  saveClientNotes: `${appConstants.adminPortalAPI}/api/Client/SaveClientNotes`,
  downloadPaymentTransactions: `${appConstants.adminPortalAPI}/api/Client/DownloadClientPaymentTransactions`,
  adjustSchedulePayment: `${appConstants.adminPortalAPI}/api/Client/AdjustScheduledPayment`,

}

export const clientManagerEndpoints = {
  getContactInformation: `${appConstants.adminPortalAPI}/api/FAQContactInformation/GetAdminContactInformation`,
  updateContactInformation: `${appConstants.adminPortalAPI}/api/FAQContactInformation/UpdateContactInformation`,
  getFaqs: `${appConstants.adminPortalAPI}/api/FAQContactInformation/GetAdminFAQ`,
  updateFaqs: `${appConstants.adminPortalAPI}/api/FAQContactInformation/UpdateFaq`,
  deleteFaqs: `${appConstants.adminPortalAPI}/api/FAQContactInformation/DeleteFaq`,
}
