import React, { ChangeEvent, useState } from 'react';
import ReportDatePicker from '../ReportDatePicker';
import { Alert, Box, Typography } from '@mui/material';
import DownloadReport from '../DownloadReport';
import { ReportType, TransactionReportDownloadTypes } from '../ReportTypes';
import { IReportDownloadType } from '../../../../redux/transactions/TransactionModel';
import { downloadFile } from '../../../../utils/common';
import {
  downloadAdportalReport,
  downloadTransactionReport,
} from '../../../../redux/transactions/TransactionsSlice';
import { ComponentOverlayLoader } from '../../../../components/Loaders/Loader';
import { useReportUtils } from '../../../../utils/customUtilHooks';

interface ITransactionReport {
  onClose: () => void;
  viewType: number;
  setViewType: (viewType: number) => void;
}

const TransactionReport = ({ onClose, viewType, setViewType }: ITransactionReport) => {
  const {
    dateRange,
    formattedStartDate,
    formattedEndDate,
    reportDownloadStatus,
    reportDownloadError,
    handleChange,
    setReportDownloadStatus,
    setReportDownloadError,
  } = useReportUtils();

  const handleDownloadReport = (reports: IReportDownloadType[]) => {
    const transactionReports = reports.filter(
      (report) => report.type === ReportType.TRANSACTION_REPORT,
    );
    const adportalTransactionReports = reports.filter(
      (report) => report.type === ReportType.ADPORTAL_TRANSACTION_REPORT,
    );
    if (transactionReports?.length) {
      downloadTransactionHistoryReports(transactionReports);
    }
    if (adportalTransactionReports?.length) {
      downloadAdportalTransactionReport(adportalTransactionReports);
    }
  };

  const downloadTransactionHistoryReports = (reports: IReportDownloadType[]) => {
    setReportDownloadStatus(true);
    setReportDownloadError('');
    Promise.all(
      reports.map((report) =>
        downloadTransactionReport({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          fileType: report?.format?.toLowerCase(),
        }),
      ),
    )
      .then((reportResults) => {
        reportResults.forEach((result) => {
          const data = result.data;
          downloadFile(
            data,
            ReportType.TRANSACTION_REPORT,
            JSON.parse(result.config.data)?.fileType,
          );
        });
        setReportDownloadStatus(false);
      })
      .catch(() => {
        setReportDownloadStatus(false);
        setReportDownloadError('Failed to download transaction report');
      });
  };
  const downloadAdportalTransactionReport = (reports: IReportDownloadType[]) => {
    setReportDownloadStatus(true);
    setReportDownloadError('');
    Promise.all(
      reports.map((report) =>
        downloadAdportalReport({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          fileType: report?.format?.toLowerCase(),
        }),
      ),
    )
      .then((reportResults) => {
        reportResults.forEach((result) => {
          const data = result.data;
          downloadFile(
            data,
            ReportType.ADPORTAL_TRANSACTION_REPORT,
            JSON.parse(result.config.data)?.fileType,
          );
        });
        setReportDownloadStatus(false);
      })
      .catch(() => {
        setReportDownloadStatus(false);
        setReportDownloadError('Failed to download adportal report');
      });
  };
  return (
    <div style={{ position: 'relative' }}>
      {reportDownloadStatus && <ComponentOverlayLoader />}
      {viewType === 1 && (
        <ReportDatePicker
          helpText={
            <Typography variant='body2' textAlign='justify'>
              Downloads both the{' '}
              <strong>Transaction Report (.csv and .txt) and Adportal Report (.csv)</strong> for
              your specified date range. Failed Payments will automatically be excluded from this
              download.
            </Typography>
          }
          heading=''
          subHeading=''
          isDateRange={true}
          dateRange={dateRange}
          handleChange={handleChange}
          onClose={onClose}
          onConfirm={() => setViewType(2)}
          confirmButtonText='Export'
        />
      )}
      {viewType === 2 && (
        <DownloadReport
          date={formattedStartDate + '-' + formattedEndDate}
          reports={TransactionReportDownloadTypes}
          heading='Ready for Download'
          subHeading='Transaction Report'
          onDownloadReport={handleDownloadReport}
          onBack={() => setViewType(1)}
          showAll={true}
        >
          {reportDownloadError && (
            <Box mb={12}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {reportDownloadError}
                </Typography>
              </Alert>
            </Box>
          )}
        </DownloadReport>
      )}
    </div>
  );
};
export default TransactionReport;
