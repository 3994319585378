import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Typography, useTheme } from '@mui/material';

import { formatDate, formatStringToDate } from '../../utils/common';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  IFilterPayload,
  IUserBasicInfo,
  PeopleTableActions,
  StatusTypes,
} from '../../redux/user/UserModel';
import {
  fetchClientPeople,
  fetchRegisteredUsers,
  fetchUserStatusList,
  updateFilters,
} from '../../redux/user/UserSlice';
import { DATE_FORMAT, FILTER_DATE_FORMAT } from '../../constants/common';
import { PATHS } from '../../routes/Routes';

import SpectrumTable, { Order } from '../../components/Spectrum/Table/SpectrumTable';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import RoleCell from '../../components/Spectrum/Table/RoleCell';
import KebabMenu from '../../components/Spectrum/Table/KebabMenu';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import ResetPassword from './ResetPassword';
import { useFilterHandlers } from '../../utils/customUtilHooks';

const PeopleTable = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isClientDetailPage = pathname.search(PATHS.CLIENTS) > -1;
  const isPeoplePage = pathname.search(PATHS.PEOPLE) > -1;
  const [searchParams] = useSearchParams();
  const isInitialMount = useRef(true);
  const {
    data,
    status,
    error,
    filters,
    filters: { sortDirection, sortColumn, length },
  } = useSelector((state: RootState) => state.userData);
  const [selectedUser, setSelectedUser] = useState<null | {
    type: PeopleTableActions;
    userSsoId: string;
  }>(null);

  const columns = [
    {
      label: 'User',
      sortKey: 'FirstName',
      accessorKey: 'user',
      render: (data: IUserBasicInfo) => (
        <div>
          <strong>
            {data.FirstName} {data.LastName}
          </strong>
          {data.User && (
            <Typography color='primary' variant='underline'>
              <strong>@{data.User}</strong>
            </Typography>
          )}
        </div>
      ),
    },
    {
      label: 'Email',
      sortKey: 'Email',
      accessorKey: 'email',
    },
    {
      label: 'Client ID\'s',
      sortKey: 'ClientIds',
      accessorKey: 'ClientIds',
    },
    {
      label: 'Status',
      sortKey: 'Status',
      accessorKey: 'status',
      render: (data: IUserBasicInfo) => <StatusCell status={data.Status} />,
      width: '30px',
    },
    {
      label: 'Created Date',
      sortKey: 'Created',
      accessorKey: 'createdDate',
      render: (data: IUserBasicInfo) => formatDate(data.Created, DATE_FORMAT),
      width: '30px',
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      render: (data: IUserBasicInfo) => {
        return (
          data.Status != StatusTypes.Pending && (
            <KebabMenu
              options={[
                {
                  label: 'View User Details',
                  onClick: () =>
                    data.Status === StatusTypes.NeedsReview
                      ? navigate(`${PATHS.REVIEW_PEOPLE}/${data.ssoId}`)
                      : navigate(`${PATHS.PEOPLE}/${data.ssoId}`),
                },
                ...(data.Status === StatusTypes.NeedsReview
                  ? []
                  : [
                      {
                        label: 'Reset Password',
                        onClick: () =>
                          setSelectedUser({
                            type: PeopleTableActions.RESET_PASSWORD,
                            userSsoId: data.ssoId,
                          }),
                      },
                    ]),
              ]}
            />
          )
        );
      },
      width: '10px',
    },
  ];

  const defaultPeopleFilters: IFilterPayload = {
    start: 0,
    length: 10,
    sortColumn: '',
    sortDirection: '',
    searchValue: '',
    startDate: '',
    endDate: '',
    status: 0,
    role: 0,
  };

  const updateFiltersAction = (updateFields: Partial<typeof filters>) => {
    dispatch(updateFilters({ ...filters, ...updateFields }));
  };
  const { handleSort, handlePageChange, handleChangeItemsPerPage } = useFilterHandlers<
    typeof filters
  >(filters, updateFiltersAction);

  useEffect(() => {
    // Default values from store
    const currentFiltersData: { [x: string]: any } = isPeoplePage
      ? defaultPeopleFilters
      : { ...filters };
    // Values From URl
    const paramEntries: any = searchParams.entries();
    for (const entry of paramEntries) {
      const [param, value] = entry;
      if (value) {
        if (param === 'startDate' || param === 'endDate') {
          currentFiltersData[param] = formatStringToDate(value);
        } else if (['companyId', 'companyType', 'role'].includes(param)) {
          currentFiltersData[param] = isClientDetailPage ? value : null;
        } else {
          currentFiltersData[param] = value;
        }
      }
    }
    // update to store
    dispatch(updateFilters({ ...currentFiltersData }));
    dispatch(fetchUserStatusList());
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (isClientDetailPage) {
      dispatch(fetchClientPeople(filters));
    } else {
      const updatedFilters: { [x: string]: any } = {
        ...filters,
        startDate: filters?.startDate
          ? formatDate(filters?.startDate.toString(), FILTER_DATE_FORMAT)
          : null,
        endDate: filters?.endDate
          ? formatDate(filters?.endDate.toString(), FILTER_DATE_FORMAT)
          : null,
      };
      // Setting applied values to URL
      const params = new URLSearchParams();
      Object.keys(updatedFilters)
        .filter((key) => !['companyId', 'companyType'].includes(key))
        .forEach((filterKey) => {
          if (updatedFilters[filterKey]) {
            params.append(filterKey, updatedFilters[filterKey]);
          }
        });
      navigate({ search: params.toString() }, { replace: true });
      dispatch(fetchRegisteredUsers(updatedFilters as IFilterPayload));
    }
  }, [filters, isInitialMount.current]);

  const isLoading = status === 'loading';

  const handleDialogClose = () => {
    setSelectedUser(null);
  };

  return (
    <>
      {status === 'failed' && (
        <Box mb={theme.spacing(5)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      <SpectrumTable
        columns={columns}
        data={data?.data}
        totalItems={data?.totalRecords || 0}
        onSort={handleSort}
        orderBy={sortColumn}
        order={sortDirection as Order}
        itemsPerPage={length}
        currentPage={filters.start / filters.length}
        loading={isLoading}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleChangeItemsPerPage}
      />
      <SpectrumDialog
        open={selectedUser?.type === PeopleTableActions.RESET_PASSWORD}
        onClose={handleDialogClose}
      >
        <ResetPassword userSsoId={selectedUser?.userSsoId || ''} onClose={handleDialogClose} />
      </SpectrumDialog>
    </>
  );
};

export default PeopleTable;
