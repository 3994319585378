import React, { ChangeEvent, useEffect } from 'react';
import { Box, Button, Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../styles/spectrum-material-theme';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilters } from '../../redux/transactions/TransactionsSlice';
import { StyledFilterContainer } from './TransactionFilterStyles';
import SpectrumDatePicker from '../../components/DatePicker/DatePicker';
import { defaultTransactionFiltersData, IPaymentStatus } from '../../redux/transactions/TransactionModel';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import SpectrumTextField from '../../components/Spectrum/TextField/SpectrumTextField';
import SearchIcon from '@mui/icons-material/Search';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface ITransactionAdvFilters {
  toggleFilters: () => void;
}

const TransactionAdvFilters = ({ toggleFilters }: ITransactionAdvFilters) => {
  const dispatch: StoreDispatch = useDispatch();
  const { filters, paymentStatusList } = useSelector((state: RootState) => state.transactionsData);
  const [filterValues, setFilterValues] = React.useState(filters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value: any = event.target.value;
    if (name == 'paymentID' || name == 'fromAmount' || name == 'toAmount') {
      value = Number(value);
    }
    setFilterValues({ ...filterValues, [name]: value, start: 0 });
  };

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  const handleApplyFilters = () => {
    dispatch(updateFilters(filterValues));
  };
  const handleClear = () => {
    dispatch(updateFilters({...filterValues, ...defaultTransactionFiltersData}))
  };

  return (
    <StyledFilterContainer>
      <Box className='header'>
        <Typography variant='h4' display='flex' alignItems='center'>
          <FilterListIcon color='primary' style={{ marginRight: theme.spacing(4) }} />
          Filters
        </Typography>
        <CloseIcon
          onClick={toggleFilters}
          sx={{ fontSize: '16px', color: 'grey', cursor: 'pointer' }}
        />
      </Box>
      <Box className='filters'>
        <Grid container spacing={8}>
          <Grid item laptop={12} container spacing={4}>
            <Grid item laptop={12}>
              <Typography variant='body2' fontWeight='bold'>
                Date
              </Typography>
            </Grid>
            <Grid item laptop={6}>
              <SpectrumDatePicker
                placeholder='Start Date'
                label=''
                value={filterValues.startDate}
                name='startDate'
                onChange={handleChange}
                size='small'
              />
            </Grid>
            <Grid item laptop={6}>
              <SpectrumDatePicker
                placeholder='End Date'
                label=''
                value={filterValues.endDate}
                name='endDate'
                onChange={handleChange}
                size='small'
              />
            </Grid>
          </Grid>
          <Grid item laptop={12}>
            <Typography variant='body2' fontWeight='bold' mb={3}>
              Payment ID
            </Typography>
            <SpectrumTextField
              placeholder='Payment ID'
              label=''
              value={filterValues.paymentID || ''}
              onChange={handleChange}
              name='paymentID'
              size='small'
              sx={{
                '& .MuiInputBase-root': { fontSize: 14 },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon sx={{ fontSize: '16px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item laptop={12}>
            <Typography variant='body2' fontWeight='bold' mb={3}>
              User
            </Typography>
            <SpectrumTextField
              placeholder='Search...'
              label=''
              value={filterValues.userName}
              name='userName'
              onChange={handleChange}
              size='small'
              sx={{
                '& .MuiInputBase-root': { fontSize: 14 },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon sx={{ fontSize: '16px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item laptop={12}>
            <Typography variant='body2' fontWeight='bold' mb={3}>
              Client
            </Typography>
            <SpectrumTextField
              placeholder='Search...'
              label=''
              value={filterValues.clientName}
              name='clientName'
              onChange={handleChange}
              size='small'
              sx={{
                '& .MuiInputBase-root': { fontSize: 14 },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon sx={{ fontSize: '16px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item laptop={12} container spacing={4}>
            <Grid item laptop={12}>
              <Typography variant='body2' fontWeight='bold'>
                Amount
              </Typography>
            </Grid>
            <Grid item laptop={6}>
              <SpectrumTextField
                placeholder='From Amount'
                label=''
                type='number'
                value={filterValues.fromAmount || ''}
                name='fromAmount'
                onChange={handleChange}
                size='small'
                sx={{
                  '& .MuiInputBase-root': { fontSize: 14 },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': { MozAppearance: 'textfield' },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AttachMoneyIcon sx={{ fontSize: '16px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item laptop={6}>
              <SpectrumTextField
                placeholder='To Amount'
                label=''
                type='number'
                value={filterValues.toAmount || ''}
                name='toAmount'
                onChange={handleChange}
                size='small'
                sx={{
                  '& .MuiInputBase-root': { fontSize: 14 },
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': { MozAppearance: 'textfield' },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AttachMoneyIcon sx={{ fontSize: '16px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item laptop={12}>
            <Typography variant='body2' fontWeight='bold' mb={3}>
              Status
            </Typography>
            <SpectrumSelectField
              variant='outlined'
              size='small'
              name='paymentStatus'
              value={filterValues.paymentStatus}
              onChange={handleChange}
              sx={{
                '& .MuiInputBase-root': { fontSize: 13 },
              }}
            >
              <MenuItem value={0}>All</MenuItem>
              {paymentStatusList.map((status: IPaymentStatus) => (
                <MenuItem key={status?.id} value={status?.id}>
                  {status?.description}
                </MenuItem>
              ))}
            </SpectrumSelectField>
          </Grid>
        </Grid>
      </Box>
      <Box className='footer'>
        <Button variant='text' onClick={handleClear} sx={{ padding: 0, fontSize: '14px' }}>
          Clear
        </Button>
        <Button
          variant='contained'
          onClick={handleApplyFilters}
          sx={{ padding: 0, borderRadius: '4px', fontSize: '14px', height: '40px' }}
        >
          Apply
        </Button>
      </Box>
    </StyledFilterContainer>
  );
};
export default TransactionAdvFilters;
