import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Link,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Close, Edit, Email, Phone } from '@mui/icons-material';
import { IClientDetails } from '../../redux/clients/ClientModel';

import RoleCell from '../../components/Spectrum/Table/RoleCell';

import { formatPhoneNumber } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { resetStatus, saveClientNotes, fetchClientDetails } from '../../redux/clients/ClientSlice';
import { RootState, StoreDispatch } from '../../redux/Store';

interface IBasicDetailsProps {
  clientDetails: IClientDetails;
}

const BasicDetails = ({ clientDetails }: IBasicDetailsProps) => {
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;

  const dispatch: StoreDispatch = useDispatch();
  const { notesError, notesStatus } = useSelector((state: RootState) => state.clientData);
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const bpId: any = searchParams.get('bpId');
  const [isEditNotesMode, setIsEditNotesMode] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState('');

  useEffect(() => {
    if (notesStatus === 'succeeded' && isEditNotesMode) {
      dispatch(resetStatus());
      dispatch(fetchClientDetails({ ssoId: clientId ?? '', bpId }));
    }
  }, [notesStatus]);

  const handleConfirm = () => {
    dispatch(
      saveClientNotes({
        clientId: clientDetails.id,
        clientType: clientDetails.clientType,
        notes: updatedNotes,
      }),
    );
  };

  const handleCancel = () => {
    setIsEditNotesMode(false);
    setUpdatedNotes('');
    dispatch(resetStatus());
  };

  const handleEditMode = () => {
    setUpdatedNotes(clientDetails?.clientNotes);
    setIsEditNotesMode(true);
  };

  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <Box sx={{ background: blue[100], border: `1px solid ${blue[200]}`, p: theme.spacing(12) }}>
        <Typography variant='h4' mb={theme.spacing(4)}>
          Client Details
        </Typography>
        <Box mb={theme.spacing(6)}>
          <Typography color={black[500]} variant='eyebrow' mb={theme.spacing(8)}>
            Primary Contact Information
          </Typography>
          <Box>
            <Box display='flex' gap={theme.spacing(4)}>
              <Typography variant='h5'>{clientDetails?.accountOwner}</Typography>
              <RoleCell role={clientDetails?.role} />
            </Box>
            <Typography variant='body1' color={primary[500]} sx={{ textDecoration: 'underline' }}>
              @{clientDetails.accountOwnerUserName}
            </Typography>
          </Box>
          <Link
            href={`mailto:${clientDetails.accountOwnerEmail}`}
            display='flex'
            padding={theme.spacing(4)}
            gap={theme.spacing(4)}
            alignItems='center'
          >
            <Email color='primary' fontSize='small' />
            <Typography variant='body1' color={black[500]} sx={{ textDecoration: 'underline' }}>
              {clientDetails?.accountOwnerEmail}
            </Typography>
          </Link>
          <Box display='flex' padding={theme.spacing(4)} gap={theme.spacing(4)} alignItems='center'>
            <Phone color='primary' fontSize='small' />
            <Typography variant='body1' color={black[500]}>
              {clientDetails?.accountOwnerPhone &&
                formatPhoneNumber(clientDetails.accountOwnerPhone)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        mt={theme.spacing(8)}
        p={theme.spacing(12)}
        sx={{ background: blue[100], border: `1px solid ${blue[200]}` }}
      >
        <Typography variant='h5' mb={theme.spacing(8)}>
          Client Notes
        </Typography>
        <Divider />
        {!isEditNotesMode ? (
          <>
            <Typography variant='body1' my={theme.spacing(8)}>
              {clientDetails?.clientNotes}
            </Typography>
            <Button
              variant='outlined'
              size='medium'
              startIcon={<Edit />}
              sx={{ borderRadius: theme.spacing(2) }}
              onClick={handleEditMode}
            >
              Edit
            </Button>
          </>
        ) : (
          <Box mt={theme.spacing(8)}>
            <TextField
              id='client-notes'
              label=''
              multiline
              rows={4}
              name='clientNotes'
              value={updatedNotes}
              onChange={(event) => setUpdatedNotes(event.target.value)}
              sx={{ backgroundColor: white[500], width: '100%' }}
            />
            {notesError && (
              <Box mt={5} mb={5}>
                <Alert severity='error' variant='outlined'>
                  <Typography variant='paragraph2' component='span' color='error'>
                    {notesError}
                  </Typography>
                </Alert>
              </Box>
            )}
            <Box display='flex' gap={theme.spacing(4)} mt={theme.spacing(8)}>
              <Button
                size='medium'
                variant='contained'
                sx={{
                  borderRadius: theme.spacing(2),
                  width: 'fit-content',
                }}
                onClick={handleConfirm}
              >
                {notesStatus === 'loading' && (
                  <CircularProgress size={18} sx={{ color: white[500], mr: 5 }} />
                )}
                Save Changes
              </Button>
              <Button startIcon={<Close />} onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default BasicDetails;
