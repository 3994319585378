import React, { ChangeEvent, useState } from 'react';
import { Button, MenuItem, Typography, useTheme } from '@mui/material';
import { ReportType, ReportTypes } from './ReportTypes';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import TransactionReport from './TransactionReport/TransactionReport';
import { IReportType } from '../../../redux/transactions/TransactionModel';
import UserReport from './UserReport/UserReport';
import RecurringPaymentsReport from './RecurringPaymentsReport/RecurringPaymentsReport';
import IosShareIcon from '@mui/icons-material/IosShare';
import SpectrumSelectField from '../../../components/Spectrum/Select/SpectrumSelect';
import { StyledReportSelect } from './ReportStyles';

const Reports = () => {
  const theme = useTheme();
  const [viewType, setViewType] = useState(1);
  const [dialogStatus, setDialogStatus] = useState(false);
  const [selectedReport, setSelectedReport] = useState<string>(ReportType.TRANSACTION_REPORT);

  const handleReportSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSelectedReport(value);
  };
  const handleClose = () => {
    setDialogStatus(false);
    setSelectedReport(ReportType.TRANSACTION_REPORT)
    setViewType(1)
  };
  const handleOpenReports = () => {
    setDialogStatus(true);
  };


  return (
    <div>
      <Button
        sx={{
          borderRadius: theme.spacing(2),
          fontWeight: 400,
          width: '100%',
          height: '40px',
        }}
        variant='outlined'
        onClick={handleOpenReports}
        size='medium'
      >
        <IosShareIcon style={{ marginRight: theme.spacing(4) }} />
        Export Reports
      </Button>
      <SpectrumDialog open={dialogStatus} onClose={handleClose}>
        {viewType === 1 && (
          <>
            <Typography variant='h2'>Export Reports</Typography>
            <StyledReportSelect>
              <Typography variant='body2' fontWeight='bold' mb={3}>
                Select Report Type*
              </Typography>
              <SpectrumSelectField
                variant='outlined'
                size='small'
                name='selectedReport'
                value={selectedReport}
                onChange={handleReportSelect}
                sx={{
                  width: '400px',
                  '& .MuiSelect-select': { backgroundColor: '#fff' },
                }}
              >
                {ReportTypes.map((report: IReportType) => (
                  <MenuItem key={report?.type} value={report?.type}>
                    {report?.label}
                  </MenuItem>
                ))}
              </SpectrumSelectField>
              {selectedReport === ReportType.TRANSACTION_REPORT && (
                <>
                  <Typography variant='body2' textAlign='justify' mt={4}>
                    Downloads both the{' '}
                    <strong>Transaction Report (.csv and .txt) and Adportal Report (.csv)</strong>{' '}
                    for your specified date range. Failed Payments will automatically be excluded
                    from this download.
                  </Typography>
                </>
              )}
              {selectedReport === ReportType.USER_REPORT && (
                <>
                  <Typography variant='body2' textAlign='justify' mt={4}>
                    Downloads the <strong>User Report</strong> (.csv) for your specified date range.
                  </Typography>
                </>
              )}
              {selectedReport === ReportType.RECURRING_PAYMENTS_REPORT && (
                <>
                  <Typography variant='body2' textAlign='justify' mt={4}>
                    Downloads the <strong>Recurring Payment Report</strong> (.csv) beginning from
                    your specified date.
                  </Typography>
                </>
              )}
            </StyledReportSelect>
          </>
        )}
        {selectedReport === ReportType.TRANSACTION_REPORT && (
          <>
            <TransactionReport
              viewType={viewType}
              setViewType={setViewType}
              onClose={handleClose}
            />
          </>
        )}
        {selectedReport === ReportType.USER_REPORT && (
          <>
            <UserReport viewType={viewType} setViewType={setViewType} onClose={handleClose} />
          </>
        )}
        {selectedReport === ReportType.RECURRING_PAYMENTS_REPORT && (
          <>
            <RecurringPaymentsReport
              viewType={viewType}
              setViewType={setViewType}
              onClose={handleClose}
            />
          </>
        )}
      </SpectrumDialog>
    </div>
  );
};
export default Reports;
