import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { IReportDownloadType } from '../../redux/transactions/TransactionModel';
import { downloadFile } from '../../utils/common';
import { ComponentOverlayLoader } from '../../components/Loaders/Loader';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import { downloadPaymentTransactionReport } from '../../redux/clients/ClientSlice';

import ReportDatePicker from '../Transactions/Reports/ReportDatePicker';
import DownloadReport from '../Transactions/Reports/DownloadReport';
import {
  ClientDetailsTransactionReportDownloadTypes,
  ReportType,
} from '../Transactions/Reports/ReportTypes';
import { useReportUtils } from '../../utils/customUtilHooks';

interface ITransactionReport {
  isOpen: boolean;
  onClose: () => void;
  bpId: number;
}

const TransactionReport = ({ isOpen, onClose, bpId }: ITransactionReport) => {
  const {
    dateRange,
    setDateRange,
    formattedStartDate,
    formattedEndDate,
    viewType,
    setViewType,
    reportDownloadStatus,
    reportDownloadError,
    handleChange,
    handleConfirm,
    setReportDownloadStatus,
    setReportDownloadError,
  } = useReportUtils();

  const handleCloseLocally = () => {
    setViewType(1);
    setDateRange([]);
    onClose();
  };

  const handleDownloadReport = (reports: IReportDownloadType[]) => {
    setReportDownloadStatus(true);
    setReportDownloadError('');
    Promise.all(
      reports.map((report) =>
        downloadPaymentTransactionReport({
          bpId: bpId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          fileType: report?.format?.toLowerCase(),
        }),
      ),
    )
      .then((reportResults) => {
        reportResults.forEach((result) => {
          const data = result.data;
          downloadFile(
            data,
            ReportType.TRANSACTION_REPORT,
            JSON.parse(result.config.data)?.fileType,
          );
        });
        setReportDownloadStatus(false);
      })
      .catch(() => {
        setReportDownloadStatus(false);
        setReportDownloadError('Failed to download transaction report');
      });
  };

  return (
    <SpectrumDialog open={isOpen} onClose={handleCloseLocally}>
      {reportDownloadStatus && <ComponentOverlayLoader />}
      {viewType === 1 && (
        <ReportDatePicker
          helpText={
            <Typography variant='body2' textAlign='justify'>
              Downloads both the{' '}
              <strong>Transaction Report (.csv and .txt) and Adportal Report (.csv)</strong> for
              your specified date range. Failed Payments will automatically be excluded from this
              download.
            </Typography>
          }
          heading='Choose Date Range'
          subHeading='Transaction Report'
          isDateRange={true}
          dateRange={dateRange}
          handleChange={handleChange}
          onClose={handleCloseLocally}
          onConfirm={handleConfirm}
          confirmButtonText='Export'
        />
      )}
      {viewType === 2 && (
        <DownloadReport
          date={`${formattedStartDate}-${formattedEndDate}`}
          reports={ClientDetailsTransactionReportDownloadTypes}
          heading='Ready for Download'
          subHeading='Transaction Report'
          onDownloadReport={handleDownloadReport}
          onBack={() => setViewType(1)}
          showAll
        >
          {reportDownloadError && (
            <Box mb={12}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {reportDownloadError}
                </Typography>
              </Alert>
            </Box>
          )}
        </DownloadReport>
      )}
    </SpectrumDialog>
  );
};
export default TransactionReport;
