import { IClientTransactions } from '../clients/ClientModel';

export interface ITransactionsResponse {
  success: boolean;
  statusCode: number;
  data: ITransactions;
  error: any;
}

export interface ITransactions {
  filteredRecords: number;
  totalRecords: number;
  data: ITransactionData[] | IClientTransactions[];
}

export interface ITransactionData {
  date: string;
  user: string;
  userName: string;
  email: string;
  client: string;
  clientName: string;
  clientId: string;
  amount: number;
  paymentID: number;
  companyId: number;
  companyType: string;
  status: string;
  errorMessage: string;
  paymentType: string;
  recurringPaymentId: string;
  description: string;
  ssoId: string;
  userBpId: string;
  invoiceOrderDetails: IInvoiceOrderDetails[];
}

export interface IInvoiceOrderDetails {
  number: string;
  type: string;
  amount: number;
}
export interface IFilterPayload {
  start: number;
  length: number;
  sortColumn: string;
  sortDirection: string;
  searchValue: string;
  paymentStatus: number;
  startDate?: string | null;
  endDate?: string | null;
  userId?: string;
  paymentID?: number | null;
  userName?: string;
  clientName?: string;
  fromAmount?: number | null;
  toAmount?: number | null;
  ssoId?: string;
  accountOwnerBpId?: string;
}
export const defaultTransactionFiltersData = {
  start: 0,
  length: 10,
  sortColumn: '',
  sortDirection: '',
  searchValue: '',
  paymentStatus: 0,
  startDate: null,
  endDate: null,
  paymentID: null,
  userName: '',
  clientName: '',
  fromAmount: null,
  toAmount: null,
  userId: '',
  ssoId: '',
};
export interface ITransactionsState {
  status: string;
  data: ITransactions;
  transactionDetails: ITransactionDetails;
  transactionDetailsStatus: string;
  transactionDetailsError: any;
  filters: IFilterPayload;
  paymentStatusList: IPaymentStatus[];
  paymentStatusListStatus: string;
  paymentStatusListError: any;
  reportDownloadStatus: string;
  reportDownloadError: any;
  error: any;
}
export interface IPaymentStatus {
  id: number;
  description: string;
  statusCode: string;
  isActive: boolean;
}
export interface IReportType {
  label: string;
  type: string;
}
export interface IExternalLinkType {
  label: string;
  link: string;
}
export interface IReportDownloadType {
  label: string;
  format: string;
  id: number;
  type?: string;
}
export interface IReportDownloadPayload {
  startDate: string;
  endDate?: string;
  fileType: string;
}
export interface IUserReportDownloadPayload {
  startDate: string;
  endDate: string;
  fileType: string;
}
export interface IRecurringPaymentsReportDownloadPayload {
  nextChargeDate: string;
  fileType: string;
}
export interface ITransactionDetailsReqPayload {
  transactionId: number;
  bpId: string;
}
export interface ICancelSchedulePaymentReqPayload {
  transactionId: number;
  bpId: string;
}
export interface IChangePaymentStatusReqPayload {
  paymentId: number;
  accountOwnerBpId: number;
}

export interface ITransactionDetails {
  SsoId?: string;
  bpId: string;
  spsTransactionId: string;
  mop: string;
  mopLastFour: string;
  totalPaidAmount: number;
  id: number;
  advertisers: IAdvertiserItem[];
}
export interface IInvoiceOrderItem {
  id: number;
  itemNumber: string;
  type: 'Inv' | 'Ord';
  isCustomAmount: boolean;
  totalAmount: number;
  oldAmount: number;
  newAmount: number;
  dueDate: string;
}
export interface IAdvertiserItem extends IAdvertiserDetails {
  items: Array<IInvoiceOrderItem>;
}

export interface ITransactionDetailsResponse {
  spsTransactionId: string;
  mop: string;
  mopLastFour: string;
  totalPaidAmount: number;
  id: number;
  advertisers: IAdvertiser[];
}

export interface IAdvertiser {
  advertiserDetails: IAdvertiserDetails;
  paidInvoices: any[];
  paidOrders: IPaidOrder[];
}

export interface IAdvertiserDetails {
  id: number;
  advertiserId: string;
  advertiserName: string;
  notes: any;
}

export interface IPaidOrder {
  id: number;
  paymentTransactionId: number;
  orderNumber: string;
  note: any;
  dueDate: string;
  orderAmount: number;
  orderDate: string;
  estimateNumber: string;
  accountExecutive: string;
  isClosed: boolean;
  taxAmount: number;
  advertiserId: number;
  isManaged: boolean;
  customAmount: number;
  isCustomAmount: boolean;
}
export interface IPaidInvoice {
  id: number;
  paymentTransactionId: number;
  invoiceNumber: string;
  documentType: string;
  totalInvoiceAmount: number;
  dueDate: string;
  invoiceDate: string;
  openAmount: number;
  orderNumbers: string;
  estimateNumber: string;
  accountExecutive: string;
  originalAmount: number;
  adjustedAmount: number;
  afterAdjustedAmount: number;
  promotionalDiscount: number;
  agencyDiscount: number;
  repFirmDiscount: number;
  netAmount: number;
  payments: number;
  isClosed: boolean;
  taxAmount: number;
  advertiserId: number;
  isManaged: boolean;
  customAmount: number;
  isCustomAmount: boolean;
}
