import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { ClientSearchTypes, ClientTypes } from '../../../redux/clients/ClientModel';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import { postFetchAdvertisers, postFetchAgencies } from '../../../redux/user/UserSlice';
import { IReviewUser, IUpdateReviewUserClients } from '../../../redux/user/UserModel';
import SpectrumSelectField from '../../../components/Spectrum/Select/SpectrumSelect';
import { StyledOutlinedBox } from './ClientSelectionModalStyles';
import AsyncAutocomplete from './Autocomplete';
import { ISubmitMultiUserDetails } from './ReviewDetails';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export interface ISubmitPayloadDetails {
  clientName: string;
  clientId: string;
  clientType: string;
  ssoId: string;
}

interface IClientSelectionModalProps {
  open: boolean;
  handleClose: () => void;
  updateConfigureUserDetails: (payload: ISubmitMultiUserDetails) => void;
  configureUserDetails: ISubmitMultiUserDetails;
  selectedAgencyIds?: string;
  selectedAdvertiserIds?: string;
}

const ClientSelectionModal = ({
  open,
  handleClose,
  updateConfigureUserDetails,
  configureUserDetails,
  selectedAgencyIds,
  selectedAdvertiserIds,
}: IClientSelectionModalProps) => {
  const [selectionFilters, setSelectionFilters] = useState<IUpdateReviewUserClients>({
    clientType: 'Agency',
    searchType: ClientSearchTypes.Id,
    searchValue: '',
    clients: [],
  });

  const handleConfirmUser = async () => {
    await updateConfigureUserDetails({
      ...configureUserDetails,
      clients: selectionFilters.clients,
    });
    handleClose();
  };

  const handleUpdateFilters = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSelectionFilters((prevPayload: IUpdateReviewUserClients) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const fetchOptions = async (searchValue: string) => {
    if (selectionFilters.clientType === ClientTypes.Agency) {
      const response = await postFetchAgencies({ ...selectionFilters, searchValue: searchValue });
      return (response?.data?.data?.agencies || []).map((agency: any) => {
        return {
          id: agency.agencyId,
          name: agency.agencyName,
        };
      });
    } else {
      const response = await postFetchAdvertisers({
        ...selectionFilters,
        searchValue: searchValue,
      });
      return (response?.data?.data?.advertisers || []).map((advertiser: any) => {
        return {
          id: advertiser.advertiserId,
          name: advertiser.advertiserName,
        };
      });
    }
  };

  const handleSelect = (id: number, data: any) => {
    setSelectionFilters({
      ...selectionFilters,
      clients: selectionFilters.clients.map((client) => {
        if (client.id === id) {
          return {
            ...client,
            clientId: data?.id,
            clientName: data?.name,
            clientType: selectionFilters.clientType,
          };
        }
        return client;
      }),
    });
  };

  const handleAddClient = () => {
    setSelectionFilters({
      ...selectionFilters,
      clients: [
        ...selectionFilters.clients,
        {
          id: new Date().getTime(),
          clientName: '',
          clientId: '',
          clientType: '',
        },
      ],
    });
  };

  const handleUnlink = (id: number) => {
    setSelectionFilters({
      ...selectionFilters,
      clients: selectionFilters.clients.filter((client) => client.id !== id),
    });
  };

  useEffect(() => {
    if (open) {
      setSelectionFilters({ ...selectionFilters, clients: configureUserDetails?.clients || [] });
    }
  }, [open]);

  const isNotValid = !!(selectionFilters.clients || []).find(
    (client) => !client.clientId || !client.clientName,
  );

  return (
    <Box style={{ zIndex: 99999 }}>
      <SpectrumDialog open={open} onClose={handleClose}>
        <Typography variant='eyebrow'>Link to Client</Typography>
        <Typography variant='h1' mb={12}>
          Client Search
        </Typography>
        <StyledOutlinedBox display={'flex'} flexDirection={'column'}>
          <Box className='filters-container'>
            <Box display='flex' alignItems='center' justifyContent='space-between'>
              <Typography variant='h5'>Filter Results</Typography>
              {(selectedAgencyIds || selectedAdvertiserIds) && <Box>
                <Typography variant='body2' fontSize='11px' color='grey'>
                  Listed Customer ID’s by Agency: {selectedAgencyIds}
                </Typography>
                <Typography variant='body2' fontSize='11px' color='grey'>
                  Listed Customer ID’s by Advertiser: {selectedAdvertiserIds}
                </Typography>
              </Box>}
            </Box>
            <Box display='flex' gap={6} mt={10}>
              <Box width='100%'>
                <SpectrumSelectField
                  variant='outlined'
                  size='small'
                  name='clientType'
                  label='CLIENT TYPE'
                  value={selectionFilters.clientType}
                  onChange={handleUpdateFilters}
                >
                  <MenuItem value='Agency'>Agency</MenuItem>
                  <MenuItem value='Advertiser'>Advertiser</MenuItem>
                </SpectrumSelectField>
              </Box>
              <Box width='100%'>
                <SpectrumSelectField
                  variant='outlined'
                  size='small'
                  name='searchType'
                  label='FILTER RESULTS BY'
                  value={selectionFilters.searchType}
                  onChange={handleUpdateFilters}
                >
                  <MenuItem value={ClientSearchTypes.Id}>Client ID</MenuItem>
                  <MenuItem value={ClientSearchTypes.Name}>Client Name</MenuItem>
                </SpectrumSelectField>
              </Box>
            </Box>
          </Box>
          <Box p={12}>
            {selectionFilters.clients.map((client) => {
              return (
                <Box
                  key={client.id}
                  mb={10}
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Box width='100%' mr={10}>
                    <AsyncAutocomplete
                      fetchOptions={fetchOptions}
                      label='Client Search'
                      onSelect={(value: any) => handleSelect(client.id, value)}
                      value={
                        client.clientId ? { id: client.clientId, name: client.clientName } : null
                      }
                    />
                  </Box>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    size='medium'
                    sx={{ borderRadius: '0px', width: '111px', fontWeight: 400, height: 40 }}
                    onClick={() => handleUnlink(client.id)}
                  >
                    Remove
                  </Button>
                </Box>
              );
            })}

            <Button onClick={handleAddClient}>
              <AddCircleIcon sx={{ mr: 2 }} /> Add Client
            </Button>
          </Box>
        </StyledOutlinedBox>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          sx={{ mt: 10, borderRadius: '5px', float: 'right' }}
          onClick={handleConfirmUser}
          disabled={isNotValid}
        >
          <Typography variant='body1' color='white'>
            Done
          </Typography>
        </Button>
      </SpectrumDialog>
    </Box>
  );
};

export default ClientSelectionModal;
