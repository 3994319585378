import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledOutlinedBox = styled(Box)(({ theme }) => {
  const grey: ColorPartial = 'lightgrey' as ColorPartial;
  const blue: ColorPartial = theme.palette.blue as ColorPartial;

  return {
    border: `1px solid ${grey}`,
    '.filters-container': {
      padding: theme.spacing(12),
      backgroundColor: blue[100],
      '.MuiSelect-select':{
        backgroundColor: theme.palette.common.white,
      }
    },
  };
});
