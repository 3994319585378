import React from 'react';
import ReportDatePicker from '../ReportDatePicker';
import { Alert, Box, Typography } from '@mui/material';
import DownloadReport from '../DownloadReport';
import { ReportType, UserReportDownloadTypes } from '../ReportTypes';
import { IReportDownloadType } from '../../../../redux/transactions/TransactionModel';
import { downloadFile } from '../../../../utils/common';
import { downloadUsersReport } from '../../../../redux/transactions/TransactionsSlice';
import { ComponentOverlayLoader } from '../../../../components/Loaders/Loader';
import { useReportUtils } from '../../../../utils/customUtilHooks';

interface IUserReportProps {
  onClose: () => void;
  viewType: number;
  setViewType: (viewType: number) => void;
}

const UserReport = ({ onClose, viewType, setViewType }: IUserReportProps) => {
  const {
    dateRange,
    formattedStartDate,
    formattedEndDate,
    reportDownloadStatus,
    reportDownloadError,
    handleChange,
    setReportDownloadStatus,
    setReportDownloadError,
  } = useReportUtils();

  const handleDownloadReport = (reports: IReportDownloadType[]) => {
    setReportDownloadStatus(true);
    setReportDownloadError('');
    Promise.all(
      reports.map((report) =>
        downloadUsersReport({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          fileType: report?.format?.toLowerCase(),
        }),
      ),
    )
      .then((reportResults) => {
        reportResults.forEach((result) => {
          const data = result.data;
          downloadFile(data, ReportType.USER_REPORT, JSON.parse(result.config.data)?.fileType);
        });
        setReportDownloadStatus(false);
      })
      .catch(() => {
        setReportDownloadStatus(false);
        setReportDownloadError('Failed to download user report');
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      {reportDownloadStatus && <ComponentOverlayLoader />}
      {viewType === 1 && (
        <ReportDatePicker
          helpText={
            <Typography variant='body2' textAlign='justify'>
              Downloads the <strong>User Report</strong> (.csv) for your specified date range.
            </Typography>
          }
          heading=''
          subHeading=''
          isDateRange={true}
          dateRange={dateRange}
          handleChange={handleChange}
          onClose={onClose}
          onConfirm={() => setViewType(2)}
          confirmButtonText='Export'
        />
      )}
      {viewType === 2 && (
        <DownloadReport
          date={formattedStartDate + '-' + formattedEndDate}
          reports={UserReportDownloadTypes}
          heading='Ready for Download'
          subHeading='User Report'
          onDownloadReport={handleDownloadReport}
          onBack={() => setViewType(1)}
          showAll={false}
        >
          {reportDownloadError && (
            <Box mb={12}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {reportDownloadError}
                </Typography>
              </Alert>
            </Box>
          )}
        </DownloadReport>
      )}
    </div>
  );
};
export default UserReport;
