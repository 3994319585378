import React from 'react';
import { Typography, Button, Box, useTheme, Stack } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import DownloadIcon from '@mui/icons-material/Download';
import EastIcon from '@mui/icons-material/East';
import { StyledReportDownloadLink } from './ReportStyles';
import DescriptionIcon from '@mui/icons-material/Description';
import { IReportDownloadType } from '../../../redux/transactions/TransactionModel';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import { appConstants } from '../../../constants/apiConstants';

interface IDownloadReportProps {
  children?: React.ReactNode;
  heading: React.ReactNode;
  subHeading: React.ReactNode;
  onDownloadReport: (reports: IReportDownloadType[]) => void;
  onBack: () => void;
  reports: IReportDownloadType[];
  showAll?: boolean;
  date: string;
}

const DownloadReport = ({
  heading,
  subHeading,
  onDownloadReport,
  onBack,
  reports = [],
  date,
  showAll,
  children,
}: IDownloadReportProps) => {
  const theme = useTheme();
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const handleDownloadAll = () => {
    onDownloadReport(reports);
  };
  const handleDownloadReport = (report: IReportDownloadType) => {
    onDownloadReport([report]);
  };
  return (
    <div>
      <Typography variant='eyebrow'>
        <IconButton
          aria-label='back'
          onClick={onBack}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        {subHeading}
      </Typography>
      <Typography variant='h2'>{heading}</Typography>
      <Stack spacing={6} mt={12} mb={12}>
        {reports.map((report: IReportDownloadType) => (
          <StyledReportDownloadLink key={report?.id} onClick={() => handleDownloadReport(report)}>
            <div className='report-icon'>
              <DescriptionIcon />
            </div>
            <div className='report-label'>
              <Typography variant='h6' sx={{ display: 'flex' }}>
                {report?.label}
                <Typography ml={4} variant='body2'>
                  {report?.format}
                </Typography>
              </Typography>
              <Typography mt={2} variant='body1' sx={{ fontSize: '13px' }}>
                <strong style={{ marginRight: theme.spacing(3) }}>Date Range:</strong>
                {date}
              </Typography>
            </div>
            <DownloadIcon style={{ color: blue[500] }} />
          </StyledReportDownloadLink>
        ))}
      </Stack>
      {children}
      {showAll && (
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 16 }}
          gap={theme.spacing(4)}
        >
          <Button
            sx={{
              borderRadius: theme.spacing(2),
              fontWeight: 400,
            }}
            variant='contained'
            onClick={handleDownloadAll}
            size='medium'
          >
            <DownloadIcon style={{ marginRight: theme.spacing(4) }} />
            Download All Reports
          </Button>
        </Box>
      )}
    </div>
  );
};

export default DownloadReport;
