import React, { useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import LinkUserComponent from './LinkUserComponent';
import LinkedClients from './LinkedClients';
import { IReviewUser } from '../../../redux/user/UserModel';
import ClientSelectionModal from './ClientSelectionModal';
import { ISubmitMultiUserDetails } from './ReviewDetails';

interface IBasicDetailsProps {
  reviewUserDetails: IReviewUser;
  updateConfigureUserDetails: (payload: ISubmitMultiUserDetails) => void;
  configureUserDetails: ISubmitMultiUserDetails;
}

const ConfigureUser = ({
  reviewUserDetails,
  updateConfigureUserDetails,
  configureUserDetails,
}: IBasicDetailsProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const unlinkCallback = (id: number) => {
    updateConfigureUserDetails({
      ...configureUserDetails,
      clients: configureUserDetails.clients.filter((client) => {
        return client.id !== id;
      }),
    });
  };

  return (
    <Box display='flex' width='100%' height='100%'>
      <Card
        sx={{
          flexGrow: 1,
          boxShadow: 4,
          p: 10,
        }}
      >
        <Typography variant='h4' mb={12}>
          Configure User
        </Typography>
        <Box mb={12}>
          <Typography variant='h5' mb={12}>
            Select Client
          </Typography>
          {configureUserDetails.clients.length > 0 && (
            <LinkedClients unlinkCallback={unlinkCallback} clients={configureUserDetails.clients} />
          )}
          <LinkUserComponent handleOpen={handleOpen} />
        </Box>
      </Card>
      <ClientSelectionModal
        open={open}
        handleClose={handleClose}
        updateConfigureUserDetails={updateConfigureUserDetails}
        configureUserDetails={configureUserDetails}
        selectedAgencyIds={reviewUserDetails?.agencyCustomerIds}
        selectedAdvertiserIds={reviewUserDetails?.advertiserCustomerIds}
      />
    </Box>
  );
};

export default ConfigureUser;
