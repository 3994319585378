import React, { ChangeEvent, useEffect, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, useTheme } from '@mui/material';
import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';
import { RootState, StoreDispatch } from '../../redux/Store';
import { updateFilters } from '../../redux/transactions/TransactionsSlice';
import FilterListIcon from '@mui/icons-material/FilterList';
import Reports from './Reports/Reports';

interface ITransactionNewFilters {
  toggleFilters: () => void;
  filtersStatus: boolean;
}

const TransactionNewFilters = ({ filtersStatus, toggleFilters }: ITransactionNewFilters) => {
  const dispatch: StoreDispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.transactionsData);
  const [filterValues, setFilterValues] = React.useState(filters);
  const theme = useTheme();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value: any = event.target.value;
    setFilterValues({ ...filterValues, [name]: value, start: 0 });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters(filterValues));
    }
  };

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item laptop={7}>
          <SpectrumSearch
            placeholder='Search...'
            sx={{ width: '100%' }}
            size='small'
            name='searchValue'
            value={filterValues.searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item laptop={2.5}>
          <Button
            sx={{
              borderRadius: theme.spacing(2),
              fontWeight: 400,
              width: '100%',
              height: '40px',
              backgroundColor: filtersStatus ? '#E1F5FB' : '#fff',
            }}
            variant='outlined'
            onClick={toggleFilters}
            size='medium'
          >
            <FilterListIcon style={{ marginRight: theme.spacing(4) }} />
            Filter Results
          </Button>
        </Grid>
        <Grid item laptop={2.5}>
          <Reports />
        </Grid>
      </Grid>
    </div>
  );
};
export default TransactionNewFilters;
