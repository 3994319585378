import React, { ChangeEvent } from 'react';
import { Typography, Grid, Button, Box, useTheme } from '@mui/material';
import CustomDateRangePicker from '../../../components/DatePicker/DateRangePicker';
import { StyledReportCalendarContainer } from './ReportStyles';
import CloseIcon from '@mui/icons-material/Close';
import { ColorPartial } from '@mui/material/styles/createPalette';
import SpectrumDatePicker from '../../../components/DatePicker/DatePicker';

interface IReportDatePicker {
  heading: React.ReactNode;
  subHeading: React.ReactNode;
  helpText: React.ReactNode;
  date?: string;
  dateRange?: Array<string>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  confirmButtonText: string;
  onClose: () => void;
  onConfirm: () => void;
  isDateRange: boolean;
}

const ReportDatePicker = ({
  heading,
  subHeading,
  helpText,
  date,
  dateRange,
  handleChange,
  confirmButtonText,
  onClose,
  onConfirm,
  isDateRange,
}: IReportDatePicker) => {
  const theme = useTheme();
  return (
    <div>
      {subHeading && <Typography variant='eyebrow'>{subHeading}</Typography>}
      {heading && <Typography variant='h2'>{heading}</Typography>}
      <Grid container spacing={15} sx={{ mt: 0 }} justifyContent="center">
        <Grid item mobile={9}>
          <StyledReportCalendarContainer>
            {isDateRange ? (
              <CustomDateRangePicker label='' value={dateRange} onChange={handleChange} inline />
            ) : (
              <SpectrumDatePicker label='' value={date} onChange={handleChange} inline />
            )}
          </StyledReportCalendarContainer>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', mt: 12, justifyContent: 'flex-end' }} gap={theme.spacing(4)}>
        <Button
          variant='outlined'
          sx={{
            fontWeight: 500,
            padding: theme.spacing(8, 12),
            borderRadius: 1
          }}
          onClick={onClose}
        >
          <Typography>Cancel</Typography>
        </Button>
        <Button
          sx={{
            borderRadius: theme.spacing(2),
            fontWeight: 500,
          }}
          disabled={isDateRange ? !(dateRange?.[0] && dateRange?.[1]) : !date}
          variant='contained'
          onClick={onConfirm}
          size='medium'
        >
          {confirmButtonText}
        </Button>
      </Box>
    </div>
  );
};

export default ReportDatePicker;
