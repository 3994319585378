import React from 'react';
import { Box, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import { StyledOutlinedBox } from './ClientSelectionModalStyles';
import { INewClient } from './ReviewDetails';

interface IRenderClientProps {
  disabled?: boolean;
  unlinkCallback: (id: number) => void;
  clients: INewClient[];
}

const LinkedClients = ({ disabled, unlinkCallback, clients }: IRenderClientProps) => {
  return (
    <>
      {clients.map((client) => (
        <StyledOutlinedBox key={client.id} mb={10} p={10}>
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            alignItems={'start'}
            flexDirection={'column'}
          >
            <Typography variant='h5'>{client.clientName}</Typography>
            <Typography variant='subtitle2'>#{client.clientId}</Typography>
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              mt={5}
              style={{ cursor: disabled ? 'default' : 'pointer' }}
              onClick={disabled ? undefined : () => unlinkCallback(client.id)}
            >
              <LinkIcon style={{ fontSize: 16 }} color={disabled ? 'disabled' : 'primary'} />
              <Typography variant='subtitle2' color={disabled ? 'text.disabled' : 'primary'} pl={5}>
                Unlink Client
              </Typography>
            </Box>
          </Box>
        </StyledOutlinedBox>
      ))}
    </>
  );
};

export default LinkedClients;
