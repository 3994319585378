import { ClientTypes } from '../clients/ClientModel';
import { INewClient } from '../../pages/PeopleDetails/ReviewDetails/ReviewDetails';

export interface IUser {
  userId: number;
  ssoId: string;
  firstName: string;
  lastName: string;
  userName: string;
  userEmail: any;
  userPhone: any;
  userNotes: any;
  status: string;
  createdDate: string;
  clients: IClientDetails[];
}
export interface IClientDetails {
  bpId: number;
  client: string;
  clientId: string;
  companyId: number;
  role: IUserRole;
  reportsToFirstName: string;
  reportsToLastName: string;
  reportsToUserName: string;
  reportsToBpId: number;
  companyType: string;
  advertisers: IAdvertiser[];
}
export interface IReviewUser {
  userId: number;
  ssoId: string;
  firstName: string;
  lastName: string;
  userName: string;
  userEmail: string;
  userPhone: string;
  companyName: any;
  agencyCustomerIds: string;
  advertiserCustomerIds: string;
  worksWithOtherAgencies: string;
  accountExecutive: string;
  recentInvoiceNumber: string;
  isUnderReview: boolean;
  createdDate: string;
  status: any;
}
export interface IConfirmReviewUserReqPayload {
  clients: INewClient[];
  ssoId: string;
}

export interface IUserBasicInfo {
  userId: number;
  FirstName: string;
  LastName: string;
  User: string;
  Client: string;
  email: string;
  ClientId: null | string;
  Role: any;
  Status: string;
  Created: string;
  ssoId: string;
}

export interface IUserResponse {
  filteredRecords: number;
  totalRecords: number;
  data: IUserBasicInfo[];
}

export interface IAdvertiserListResponse {
  id: number;
  agencyId: number;
  tblAgency: any;
  advertiserId: number;
  tblAdvertiser: IAdvertiser;
}

export interface IAgency {
  id: number;
  agencyId: number;
  agencyName: string;
}

export interface IAdvertiser {
  id: number;
  advertiserId: number;
  advertiserName: string;
}

export interface IUserRole {
  id: number;
  name: string;
}

export interface IReportUser {
  id: number;
  userId: number;
  tblUser: TblUser;
  userRoleId: number;
  tblUserRole: TblUserRole;
  agencyId: number;
  tblAgency: TblAgency;
  advertiserId: any;
  tblAdvertiser: any;
  reportsTo: number;
  isActive: boolean;
}
export interface TblUser {
  id: number;
  cognitoUserId: string;
  userName: string;
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsDateTime: string;
  firstName: string;
  lastName: string;
  userStatusId: number;
  tblUserStatus: TblUserStatus;
  userNotes: any;
  userEmail: string;
}

export interface TblUserRole {
  id: number;
  name: string;
}

export interface TblUserStatus {
  id: number;
  name: string;
}

export interface TblAgency {
  id: number;
  agencyId: number;
  agencyName: string;
}

export interface IClientDetailsResponse {
  id: number;
  clientType: string;
  clientName: string;
  clientId: string;
}

export interface IClientDetailsPayload {
  ssoId: string;
  clientType: string;
  clientId: string;
}

export interface IUsersState {
  status: string;
  data: null | IUserResponse;
  // Agency List
  agencyList: IAgency[];
  agencyListError: any;
  agencyListStatus: string;
  // Advertisers List
  advertiserList: IAdvertiser[];
  advertiserListError: any;
  advertiserListStatus: string;
  // Agency Advertiser List
  agencyAdvertiserListStatus: string;
  agencyAdvertiserList: IAdvertiserListResponse[];
  agencyAdvertiserListStatusError: string;
  // Report To
  reportsToUsersStatus: string;
  reportsToUsers: IReportUser[];
  reportsToUsersStatusError: string;
  // User Roles
  userRolesStatus: string;
  userRoles: IUserRole[];
  userRolesStatusError: string;
  // Reset User Password
  resetPasswordStatus: string;
  resetPasswordError: any;
  // Review User Details
  reviewUserDetails: null | IReviewUser;
  reviewUserDetailsStatus: string;
  reviewUserDetailsError: string;
  // User Details
  userDetails: null | IUser;
  userDetailsStatus: string;
  userDetailsStatusError: string;
  error: any;
  filters: IFilterPayload;
  userStatusList: IUserStatus[];
  userStatusListStatus: string;
  userStatusListStatusError: string;
  scheduleAutoPayments: any[];
  // Selected Client Details
  selectedClientDetailsStatus: string;
  selectedClientDetailsResponse: IClientDetailsResponse;
  selectedClientDetailsError: string;
}

export interface IFilterPayload {
  start: number;
  length: number;
  sortColumn: string;
  sortDirection: string;
  searchValue: string;
  startDate: null | string | Date;
  endDate: null | string | Date;
  status?: number;
  role?: number;
  companyId?: number | null;
  companyType?: string | null;
}

export interface IUserStatus {
  id: number;
  name: string;
}
export enum Roles {
  ACCOUNT_OWNER = 1,
  MANAGER = 2,
  TEAM_MEMBER = 3,
}
export enum RoleValues {
  ACCOUNT_OWNER = 'Account Owner',
  MANAGER = 'Manager',
  TEAM_MEMBER = 'Team Member',
}
export interface IUserRequestPayload {
  ssoId: string;
  bpId: number;
  clientType: string;
  clientId: number;
  roleId: number;
  reportsToBpId: number | null;
  notes: string;
  advertisers: IAdvertiser[];
}

export interface ILocalUserDetails {
  companyType: ClientTypes;
  client: any;
  roleId: any;
  reportsToBpId: number | null;
  advertisers: IAdvertiser[];
}
export interface IErrorLocalUserDetails {
  companyType: string;
  client: string;
  roleId: string;
  reportsToBpId: string;
  advertisers: string;
}

export interface IUpdateReviewUserClients {
  clientType: string;
  searchType: string;
  searchValue: string;
  clients: INewClient[];
}

export enum PeopleTableActions {
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACTIVATE_DEACTIVATE_USER = 'ACTIVATE_DEACTIVATE_USER',
}

export enum StatusTypes {
  Processed = 'Processed',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Processing = 'Processing',
  Received = 'Received',
  Active = 'Active',
  Pending = 'Pending',
  NeedsReview = 'NeedsReview',
}
