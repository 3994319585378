import { Box, styled } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledFilterContainer = styled(Box)(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return {
    border: `1px solid ${grey[300]}`,
    '.header': {
      padding: theme.spacing(8),
      display: 'flex',
      justifyContent: 'space-between',
    },
    '.filters': {
      padding: theme.spacing(8),
      margin: theme.spacing(2, 0, 10, 0),
    },
    '.footer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#F9F9F9',
      borderTop: `1px solid ${grey[300]}`,
      height: '70px',
      padding: theme.spacing(0,8),
    },
  };
});
