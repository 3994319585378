import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import {
  changeTransactionStatus,
  fetchClientTransactions,
} from '../../redux/transactions/TransactionsSlice';
import { IChangePaymentStatusReqPayload } from '../../redux/transactions/TransactionModel';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const ChangePaymentStatusDialog = ({
  paymentStatusResult,
  paymentStatusPayload,
  setPaymentStatusPayload,
  setPaymentStatusResult,
}: {
  paymentStatusResult: { status: string; error: string };
  paymentStatusPayload: IChangePaymentStatusReqPayload | null;
  setPaymentStatusPayload: (data: IChangePaymentStatusReqPayload | null) => void;
  setPaymentStatusResult: (data: { status: string; error: string }) => void;
}) => {
  const { clientId } = useParams();
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.transactionsData);
  const { clientDetails } = useSelector((state: RootState) => state.clientData);

  const handleClose = () => {
    setPaymentStatusPayload(null);
    setPaymentStatusResult({ status: 'idle', error: '' });
  };
  const handleConfirm = () => {
    setPaymentStatusResult({ status: 'loading', error: '' });
    changeTransactionStatus(paymentStatusPayload as IChangePaymentStatusReqPayload)
      .then((result) => {
        setPaymentStatusResult({ status: 'succeeded', error: '' });
        handleClose();
        dispatch(
          fetchClientTransactions({
            ...filters,
            ssoId: clientId,
            accountOwnerBpId: clientDetails?.accountOwnerBpId,
          }),
        );
      })
      .catch((error) => {
        setPaymentStatusResult({ status: 'failed', error: error?.error });
      });
  };
  return (
    <ConfirmationDialog
      loading={paymentStatusResult.status === 'loading'}
      open={!!paymentStatusPayload?.paymentId}
      {...(paymentStatusResult.status === 'failed'
        ? {
            title: '',
            heading: (
              <Box display='flex' alignItems='center' gap={theme.spacing(4)}>
                <WarningAmberIcon color='error' fontSize='large' />
                Payment status could not be changed
              </Box>
            ),
            subHeading: (
              <>
                <Typography color='error' fontSize='small' mt={2} mb={2}>
                  {paymentStatusResult.error}
                </Typography>
                We ran into an issue changing this payment status. For more assistance, please
                contact:
                <Typography color='primary' variant='underline'>
                  dl-reach-mediadevelopmentsupport@charter.com
                </Typography>
              </>
            ),
            cancelButtonText: 'Close',
            confirmButtonText: '',
          }
        : {
            title: 'Payment Status',
            heading: 'Confirm Payment status as Failed',
            subHeading: 'Are you sure you want to change this payment status as failed?',
            confirmButtonText: 'Change Status',
            showCancelIcon: true,
          })}
      onClose={handleClose}
      onConfirm={handleConfirm}
    />
  );
};

export default ChangePaymentStatusDialog;
